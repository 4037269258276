.slider_equipment{
	height:220px;
}
.slider_equipment .block_slider img{
	max-height:220px;
    height: auto;
    max-width: 100%;
	margin: 0 auto;
}
.slider_equipment .block_slider{
	display:flex !important;
	align-items: center;
	justify-content: center;
	height:220px;
}
