.h2-title-light,
.typo-wrapper h2 {
  font-size: 25px;
  padding-bottom: 13px;
  color: #0066b3;
  font-weight: normal;
  text-align:center;
  border-style: solid;
  border-width: 0px 0px 2px;
  -moz-border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
  -webkit-border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
  -o-border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
  border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
  margin-bottom: 15px;
}

.h2-title-simple {
  font-size: 25px;

  padding: 17px 20px;

  text-align: left;

  color: #0065b3;
}

.h2-title-head, .typo-wrapper--title-head h2 {
  font-size: 23px;
  color: #fff;

  display: block;

  width: 100%;
  padding: 17px 20px;
  margin-bottom:15px;

  text-align: center;

  background-color: #0065b3;
}

.h3-title-light {
  font-size: 25px;
  padding-bottom: 13px;
  color: #0066b3;
  font-weight: normal;
  text-align: center;
}

.h3-title-block {
  font-size: 23px;

  position: relative;

  padding: 17px 20px;

  color: #fff;
  background-color: #0065b3;

  &--sub {
    background-color: #bdbec0;
    color: #2d2c2e;
  }

  &--sub-grad {
    color: #fff;
    background: url(/images/index/pluse_title.jpg) 0 0 no-repeat;
    background-size: auto;
    background-size: cover;
  }
}

.typo-wrapper-h3--block h3 {
  font-size: 23px;

  position: relative;

  padding: 17px 20px;

  color: #fff;
  background-color: #0065b3;
}

.typo-wrapper-h3--grey h3 {
  font-size: 23px;

  display: block;
  width: 100%;
  padding: 17px 20px;

  color: #2d2c2e;
  background-color: #bdbec0;
}
