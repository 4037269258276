#workarea .page--support-vs .block_system_schema .schema_elem {
    max-width: 250px;
    min-height: 60px;
    position: absolute;
}

.page--support-vs .block_system_schema--fixed-height {
  height: 325px;
}

.page--support-vs .block_system_schema .elem_highlight {
    background: linear-gradient(to right, #983560, #d84246);
}

.page--support-vs .block_system_schema .elem_highlight .schema_elem_num::before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 12px;
    left: 10px;
}

.page--support-vs .block_system_schema .elem_place1 {
    top: 120px;
    left: 35px;
}

.page--support-vs .block_system_schema .elem_place2 {
    top: 205px;
    left: 170px;
}

.page--support-vs .block_system_schema .elem_place3 {
    top: 120px;
    left: 340px;
}

.page--support-vs .block_system_schema .elem_place4 {
    top: 205px;
    left: 480px;
}

.page--support-vs .block_system_schema .elem_place5 {
    top: 120px;
    right: 180px;
}

.page--support-vs .block_system_schema .elem_place6 {
    top: 205px;
    right: 45px;
}
.page--support-vs .block_system_plus h3::after {
    content: "";
    display: block;
    width: 250px;
    height: 61px;
    position: absolute;
    background: url("/images/support/guarantee_label.svg") no-repeat center center;
    background-size: contain;
    top: 25px;
    right: 35px;
}

@media (max-width: 1199px) {
    #workarea .page--support-vs .block_system_schema .schema_elem {
        position: static;
        margin: 7px 0;
    }

    .page--support-vs .block_system_schema .schema_elems {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        height: 100%;
    }
}

@media (max-width: 991px) {
    #workarea .page--support-vs .block_system_schema {
        height: auto;
    }

    .page--support-vs .block_system_schema .schema_elems {
        justify-content: space-around;
    }

    .page--support-vs .block_system_plus h3::after {
        width: 200px;
        height: 49px;
        top: 30px;
    }
}

@media (max-width: 660px) {
    .page--support-vs .block_system_schema .schema_elems {
        flex-direction: column;
        align-items: center;
    }

    .block_system_plus h3::after {
        display: none;
    }

    #workarea .block_system_plus h3 {
        padding-left: 20px;
    }
}
