#workarea .block_history {
    width: 100%;
    height: 3720px;
    background: url(/images/include/img_history.svg) 49% 0 no-repeat;
	background-size: contain;
}

#workarea .block_history .block_history_cell {
    width: 50%;
    min-height: 430px;
}

#workarea .block_history .block_history_cell.history_cell_float1 {
    float: left;
    padding: 50px 125px 30px 0;
}

#workarea .block_history .block_history_cell.history_cell_float2 {
    float: right;
    padding: 50px 0 30px 125px;
}

#workarea .block_history .block_history_cell.margin_cell {
    margin-top: 200px;
}

#workarea .block_history {
    clear: both;
}

#workarea .block_history .block_history_cell .block_history_cell_year {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: 0 auto;
    color: #fff;
    background-color: #0065b3;
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#workarea .block_history .block_history_cell .block_history_cell_year.white {
    border: 1px solid #bebfc1;
    color: #0065b3;
    background-color: #fff;
}

#workarea .block_history .block_history_cell .block_history_cell_text {
    color: #58585a;
    font-size: 15px;
    margin-top: 45px;
}

@media (max-width: 1199px) {
    #workarea .block_history .block_history_cell.history_cell_float1,
    #workarea .block_history .block_history_cell.history_cell_float2 {
        padding-top: 0;
    }

    #workarea .block_history .block_history_cell.margin_cell {
        margin-top: 135px;
    }

    #workarea .block_history .block_history_cell .block_history_cell_year {
        width: 110px;
        height: 110px;
        font-size: 30px;
    }
}

@media (max-width: 991px) {
    #workarea .block_history {
        background: none;
        height: auto;
        margin-bottom: 60px;
    }

    #workarea .block_history .block_history_cell.history_cell_float1,
    #workarea .block_history .block_history_cell.history_cell_float2 {
        float: none;
        padding: 10px 0px;
    }

    #workarea .block_history .block_history_cell {
        min-height: 0;
        width: 100%;
    }

    #workarea .block_history .block_history_cell.margin_cell {
        margin-top: 0;
    }

    #workarea .block_history .block_history_cell .block_history_cell_text {
        margin-top: 30px;
    }

    #workarea .block_history .block_history_cell .block_history_cell_year {
        border: 1px solid #bebfc1;
        color: #0065b3;
        background-color: #fff;
    }

    #workarea .block_history .block_history_cell:nth-child(odd) .block_history_cell_year {
        color: #fff;
        background-color: #0065b3;
        border: none;
    }
}

@media (max-width: 500px) {
    #workarea .block_history .block_history_cell.history_cell_float1,
    #workarea .block_history .block_history_cell.history_cell_float2 {
        padding: 10px;
    }
}
