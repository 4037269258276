#workarea .block_system_schema {
    width: 100%;
    position: relative;
    z-index: 10;
    background: url(/images/include/system_schema1.png) 50% 63% no-repeat, url(/images/include/background_schema1.png) 0 0 no-repeat, url(/images/include/background_schema2.png) 100% 0 no-repeat;
    background-color: #0065b3;
    margin-bottom: 110px;
}

#workarea .block_system_schema .schema_element {
    min-width: 210px;
    max-width: 270px;
    padding: 0 30px;
    height: 65px;
    background-color: #0059a9;
    cursor: pointer;
    text-decoration: underline;
    color: #fff;
    border-radius: 30px;
    line-height: 1.15;
    font-size: 15px;
    position: absolute;
}

#workarea .block_system_schema .schema_element:hover {
    text-decoration: none;
    background-color: #0094da;
}

#workarea .block_system_schema .element_position1 {
    top: 20px;
    left: calc(50% - 131px);
}

#workarea .block_system_schema .element_position2 {
    top: 143px;
    left: 162px;
}

#workarea .block_system_schema .element_position3 {
    bottom: 28px;
    left: 212px;
}

#workarea .block_system_schema .element_position4 {
    bottom: 28px;
    right: 212px;
}

#workarea .block_system_schema .element_position5 {
    top: 143px;
    right: 125px;
}

#workarea .block_system_schema .schema_element_text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 65px;
}
#workarea .block_system_schema .schema_element .schema_element_link {
    color:#fff;
}
#workarea .block_system_schema .schema_element .schema_element_link:hover {
    color:#fff;
	text-decoration:none;
}

#workarea .block_schema_equipment {
    width: 100%;
    position: relative;
    z-index: 10;
    height: 650px;
    background: url(/images/include/schema_equipment.png) 50% 50% no-repeat;
    background-color: #0065b3;
    margin-bottom: 20px;
}

#workarea .block_schema_equipment h3{
    font-size:25px;
    margin:10px 0 20px;
    color:#fff;
    text-align:center;
    display:none;
    width:100%;
}

#workarea .block_schema_equipment .schema_element {
    min-width: 190px;
    max-width: 190px;
    padding: 0 10px;
    height: 62px;
    background-color: #0059a9;
    cursor: pointer;
    text-decoration: underline;
    color: #fff;
    border-radius: 30px;
    line-height: 1.15;
    font-size: 13px;
    position: absolute;
}
#workarea .block_schema_equipment .schema_element.noactive {
    min-width: 190px;
    max-width: 190px;
    padding: 0 10px;
    height: 62px;
    background-color: #0059a9;
    cursor: auto;
    text-decoration: none;
    color: #fff;
    border-radius: 30px;
    line-height: 1.15;
    font-size: 13px;
    position: absolute;
}
#workarea .block_schema_equipment .schema_element.noactive:hover{
    background-color: #0059a9;
}

#workarea .block_schema_equipment .schema_element:hover {
    text-decoration: none;
    background-color: #0094da;
}

#workarea .block_schema_equipment .element_position1 {
    top: 140px;
    right: 155px;
}

#workarea .block_schema_equipment .element_position2 {
    top: 80px;
    right: 342px;
}

#workarea .block_schema_equipment .element_position3 {
    top: 80px;
    left: 342px;
}

#workarea .block_schema_equipment .element_position4 {
    top: 152px;
    left: 170px;
}

#workarea .block_schema_equipment .element_position5 {
    top: 243px;
    left: 109px;
}

#workarea .block_schema_equipment .element_position6 {
    bottom: 247px;
    left: 109px;
}

#workarea .block_schema_equipment .element_position7 {
    bottom: 152px;
    left: 170px;
}

#workarea .block_schema_equipment .element_position8 {
    bottom: 80px;
    left: 342px;
}

#workarea .block_schema_equipment .element_position9 {
    bottom: 80px;
    right: 342px;
}

#workarea .block_schema_equipment .element_position10 {
    bottom: 140px;
    right: 155px;
}

#workarea .block_schema_equipment .element_position12 {
    display:none;
}

#workarea .block_schema_equipment .schema_element_text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 62px;
}
#workarea .block_schema_equipment .schema_element_link {
	color:#fff;
}
#workarea .block_schema_equipment .schema_element_link:hover {
	color:#fff;
	text-decoration:none;
}

#workarea .block_schema_equipment .schema_element_scout {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    width: 144px;

    	/*-webkit-transition: all 100ms ease-out 0ms;
        -moz-transition: all 100ms ease-out 0ms;
        -o-transition: all 100ms ease-out 0ms;
        transition: all 100ms ease-out 0ms;*/
}

#workarea .block_schema_equipment .schema_element_scout img {
    width: 100%;
}

/*#workarea .block_schema_equipment .schema_element_scout:hover{
	width:147px;
}
#workarea .block_schema_equipment .schema_element_scout:hover.element_position11{
	top: 232px;
    right: 196px;
}*/
#workarea .block_schema_equipment .schema_element_scout.element_position11 {
    top: 234px;
    right: 198px;
}

@media (max-width: 1199px) {
    #workarea .block_system_schema .element_position2 {top: 143px;left: 75px;}
    #workarea .block_system_schema .element_position3 {bottom: 28px;left: 125px;}
    #workarea .block_system_schema .element_position4 {bottom: 28px;right: 125px;}
    #workarea .block_system_schema .element_position5 {bottom: 28px;right: 25px;}
    #workarea .block_schema_equipment .element_position1 {right: 60px;top:146px}
    #workarea .block_schema_equipment .element_position2 {left:430px;}
    #workarea .block_schema_equipment .element_position3 {left:225px;}
    #workarea .block_schema_equipment .element_position4 {left:70px;}
    #workarea .block_schema_equipment .element_position5 {left:5px;}
    #workarea .block_schema_equipment .element_position6 {left:10px;}
    #workarea .block_schema_equipment .element_position7 {left:75px;}
    #workarea .block_schema_equipment .element_position8 {left:250px;}
    #workarea .block_schema_equipment .element_position9 {right:205px;}
    #workarea .block_schema_equipment .element_position10 {bottom: 156px;right: 54px;}
    #workarea .block_schema_equipment .schema_element_scout.element_position11 {right:85px;}
}

@media (max-width: 991px) {
    #workarea .block_system_schema {
        height: auto;
        background: url(/images/include/system_schema1.png) 0 50% no-repeat;
        background-color: #0065b3;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 10px;
    }

    #workarea .block_schema_equipment h3{
        display:block;
        order:-2;
    }

    #workarea .block_system_schema .schema_element {
        position: static;
        margin: 5px 0;
        width: 50%;
        max-width: none;
    }

    #workarea .block_schema_equipment .element_position12 {
        display:block;
        order:-1;
    }

    #workarea .block_schema_equipment .schema_element_scout{
        display:none;
    }

    #workarea .block_schema_equipment{
        height: auto;
        background-image:none;
        background-color: #0065b3;
        display:flex;
        flex-wrap:wrap;
        padding:5px 0 20px;
    }

    #workarea .block_schema_equipment .schema_element{
        position: static;
        margin: 5px;
        width: 48%;
        flex-grow:1;
        max-width: none;
    }
	#workarea .block_schema_equipment .schema_element.noactive{
        position: static;
        margin: 5px;
        width: 48%;
        flex-grow:1;
        max-width: none;
    }
}

@media (max-width: 660px) {
    #workarea .block_system_schema {
        background-position: 50% 0;
        padding-top: 300px;
    }

    #workarea .block_schema_equipment{
        margin-bottom:50px;
    }

    #workarea .block_system_schema .schema_element,
    #workarea .block_schema_equipment .schema_element,
    #workarea .block_schema_equipment .schema_element.noactive
	{
        width: 100%;
    }

    #workarea .block_system_schema .schema_element br,
    #workarea .block_schema_equipment .schema_element br{
        display: none;
    }

    #workarea .block_schema_equipment .schema_element{
        width:100%;
    }
}
