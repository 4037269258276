.block_solutions_container {
    background-color: #fff;
    padding: 15px 30px 15px 30px;
	display:flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
    z-index: 10;
}
.block_solutions_container .block_solutions_content{
	width:33.3%;
	min-height:110px;
	cursor:pointer;	
	padding:5px 15px 5px 98px;
	display:flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	justify-content: center;
	position: relative;
    z-index: 10;
	background-position:12px 50%;
	background-repeat:no-repeat;
	background-size:71px 71px;
}
.block_solutions_container .border_top{
	border-top:2px solid #e6e7e8;
}
.block_solutions_container .border_bottom{
	border-bottom:2px solid #e6e7e8;
}
.block_solutions_container .border_left{
	border-left:2px solid #e6e7e8;
}
.block_solutions_container .border_right{
	border-right:2px solid #e6e7e8;
}

.block_solutions_container .block_solutions_content_text1{
	font-size:14px;
	color:#2d2c2e;
	position: relative;
    z-index: 10;
	text-decoration:none;
}
.block_solutions_container .block_solutions_content_text2{
	font-size:16px;
	text-align:left;
	padding:0;
	line-height:1.35;
	text-transform: none;
	color:#0066b3;
	text-decoration:none;
	font-family:"RobotoBold", Tahoma, sans-serif;
	position: relative;
    z-index: 10;
}
.block_solutions_container .background1{
	background-image: url(/upload/medialibrary/3bc/3bc85be3596a5779a2bf47a686596c21.svg);
}
.block_solutions_container .background4{
	background-image: url(/upload/medialibrary/83f/83ffe9592454930f6b41e5b0c6f021e1.svg);
}
.block_solutions_container .background2{
	background-image: url(/upload/medialibrary/282/2823bd39163e640bacde09a908211cb3.svg);
}
.block_solutions_container .background3{
	background-image: url(/upload/medialibrary/ee7/ee75a076b199e05eb3c051372d2cf017.svg);
}
.block_solutions_container .background5{
	background-image: url(/upload/medialibrary/9ed/9edc110930e394b5163a57c4271fb033.svg);
}
.block_solutions_container .background6{
	background-image: url(/upload/medialibrary/26d/26d30e02fd3d4835cd6d93ecf522da62.svg);
}
.block_solutions_container .background7{
	background-image: url(/upload/medialibrary/099/099a8126afa17cd222a545d8cd9a9729.svg);
}
.block_solutions_container .block_solutions_content:hover.background1{
	background-image: url(/upload/medialibrary/88d/88d1f828e9071aec40439b7c2d1e8a55.svg);
}
.block_solutions_container .block_solutions_content:hover.background2{
	background-image: url(/upload/medialibrary/22f/22fd6f0d979afaaf25a14b6ef0e777d3.svg);
}
.block_solutions_container .block_solutions_content:hover.background3{
	background-image: url(/upload/medialibrary/d9d/d9d01bab0b57f24fba03931c8329250b.svg);
}
.block_solutions_container .block_solutions_content:hover.background4{
	background-image: url(/upload/medialibrary/481/481840fc9bde2b8cd5ca45ca371d41e3.svg);
}
.block_solutions_container .block_solutions_content:hover.background5{
	background-image: url(/upload/medialibrary/ef4/ef4cdb6d1a27f172c9d76b4e4576ff2c.svg);
}
.block_solutions_container .block_solutions_content:hover.background6{
	background-image: url(/upload/medialibrary/528/528e41c6bfdb7abdf42a24b4cdd00413.svg);
}
.block_solutions_container .block_solutions_content:hover.background7{
	background-image: url(/upload/medialibrary/f68/f685720ff9d166faf4e33cf7f97418f5.svg);
}
.block_solutions_container .block_solutions_content .block_solutions_shadow{
	position: absolute;
    z-index: 1;
    top: 10px;
    bottom: 10px;
    right: 10px;
    left: 10px;
    background-color: transparent;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}
.block_solutions_container .block_solutions_content:hover .block_solutions_shadow {
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
	top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
@media only screen and (min-width: 992px){
	.block_solutions_container .block_solutions_content_text2 br{display: none;}
}
@media only screen and (max-width: 991px){
	.block_solutions_container .block_solutions_content {width: 30%; margin: 0 1.5%; min-width:130px; min-height: auto; padding: 95px 10px 10px 10px; align-items: center; background-position:50% 13px; background-repeat:no-repeat;}
	.block_solutions_container .block_solutions_content_text1{display:none;}
	.block_solutions_container .block_solutions_content_text2 {font-size: 15px; color: #58595b; text-align:center;}
	.block_solutions_container .border_left { border-left: none;}
	.block_solutions_container .border_right{ border-right: none;}
	.block_solutions_container .border_top { border-top: none;}
	.block_solutions_container .block_solutions_content:hover.background1{ background-image: url(/upload/medialibrary/3bc/3bc85be3596a5779a2bf47a686596c21.svg);	}	
	.block_solutions_container .block_solutions_content:hover.background2{background-image: url(/upload/medialibrary/282/2823bd39163e640bacde09a908211cb3.svg);}
	.block_solutions_container .block_solutions_content:hover.background3{background-image: url(/upload/medialibrary/ee7/ee75a076b199e05eb3c051372d2cf017.svg);}
	.block_solutions_container .block_solutions_content:hover.background4{background-image: url(/upload/medialibrary/83f/83ffe9592454930f6b41e5b0c6f021e1.svg);}
	.block_solutions_container .block_solutions_content:hover.background5{background-image: url(/upload/medialibrary/9ed/9edc110930e394b5163a57c4271fb033.svg);}
	.block_solutions_container .block_solutions_content:hover.background6{background-image: url(/upload/medialibrary/c9a/c9a1b4ecb1d25f596cf62eff3e184a3b.svg);}
	.block_solutions_container .block_solutions_content:hover.background7{background-image: url(/upload/medialibrary/099/099a8126afa17cd222a545d8cd9a9729.svg);}
	.block_solutions_container .block_solutions_content:hover .block_solutions_shadow {box-shadow: none;}
	.block_solutions_container .block_solutions_content:hover {background-color: #e7e8ea;}
	.block_solutions_container {padding: 25px 0;}
}
@media only screen and (max-width: 660px){
	.block_solutions_container .block_solutions_content {width: 44%; margin: 0 3%; max-width: 220px;}
}
@media only screen and (max-width: 500px){
	.block_solutions_container .block_solutions_content{padding-left: 0; padding-right: 0}
}