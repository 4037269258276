#workarea .analytics_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-size: auto 100%;
}

#workarea .analytics_block .analytics_service {
    background-color: #fff;
    width: 49%;
    padding: 20px 20px 30px;
}

#workarea .analytics_service .analytics_service_title {
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #bebec0;
    text-align: center;
    color: #0065b3;
    font-size: 23px;
    padding-bottom: 12px;
}

#workarea .analytics_service .analytics_service_plus {
    padding: 0;
    margin-top: 20px;
}

#workarea .analytics_service .analytics_service_plus li {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
}

#workarea .analytics_service .analytics_service_plus li:before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0065b3;
    margin-right: 10px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}

#workarea .clients_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#workarea .clients_list_title {
    width: 100%;
    text-transform: uppercase;
    font-size: 19px;
    color: #0066b3;
    text-align: center;
    margin: 40px 0;
}

#workarea .clients_list .client {
    background-color: #fff;
    width: 31.6%;
    margin-bottom: 25px;
    text-align: center;
    padding: 20px 0 30px;
    position: relative;
}

#workarea .client .client_content {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
}

#workarea .client .client_hover_bg {
    position: absolute;
    z-index: 1;
    top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    background-color: transparent;
    transition: all 200ms ease-out 0ms;
}

#workarea .clients_list .client:hover .client_hover_bg {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
}

#workarea .clients_list .client_logo {
    padding: 10px 0 30px;
}

#workarea .unit-schema {
    margin: 15px 0 0;
    text-align: center;
}

#workarea .unit-schema img {
    width: 100%;
}

#workarea .unit-schema h4 {
    text-transform: uppercase;
    font-size: 19px;
}

@media (max-width: 991px) {
    #workarea .analytics_block .analytics_service {
        width: 100%;
        margin-bottom: 20px;
    }

    #workarea .clients_list .client {
        width: 48%;
    }
}

@media (max-width: 660px) {
    #workarea .container .analytics_block {
        background-color: transparent;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 500px) {
    #workarea .container .analytics_block {
        margin-bottom: 0;
    }

    #workarea .clients_list .client {
        width: 100%;
    }
}
