.b-block_support {
	margin:30px 0 20px 0;
	background-color:#f3f3f4;
	padding:14px 15px 40px 15px;
}
#workarea .block_support{
	margin:30px 0 20px 0;
	background-color:#f3f3f4;
	padding:14px 15px 40px 15px;
}
#workarea .block_support .block_title{
	padding:0;
}
#workarea .block_support .block_title h3{
	background: linear-gradient(to right, #1762a9, #122e51);
	color:#fff;
	font-size:23px;
	text-transform:uppercase;
	padding:20px 15px;
}
#workarea .block_support .block_title .text{
	font-size:17px;
	color:#2d2c2e;
	line-height:1.3;
	padding:25px 15px;
}
#workarea .block_support .block_support_container{
	padding:0 8px;
	display:flex;
	 -webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
}
#workarea .block_support .block_support_content{
	padding:0;
	margin:10px 22px 15px 22px;
	cursor:pointer;
	overflow: hidden;
    position: relative;
	width:29%;
}
#workarea .block_support .block_support_content .content_text{
	text-align:center;
	background-color:#fff;
	font-size:17px;
	color:#0066b3;
	height:60px;
	vertical-align:middle;
	line-height:1.2;
	text-decoration:none;
	display:flex;
	width:100%;
	justify-content:center;
}
#workarea .block_support .block_support_content:hover .content_text{
	text-decoration:none;
}
#workarea .block_support .block_support_content .support_images{
	display:block;
	width:100%;
}
#workarea .block_support .block_support_content .padding1{
	padding-top:8px;
}
#workarea .block_support .block_support_content .padding2{
	padding-top:19px;
}
#workarea .block_support .block_support_content .anim_block{
	position: absolute;
    left:0;
    top:245px;
	overflow: hidden;
	display:flex;
	align-items: center;
    text-align: left;
    width:100%;
	height:calc(100% - 60px);
    background-color: rgba(0,0,0,0.6);
    opacity: 0;
    color: #fff;
    transition: all 0.3s ease-in-out;
	font-size:15px;
}
#workarea .block_support .block_support_content:hover .anim_block {
    top:60px;
    opacity: 1;
    transition-delay: 0.1s;
}
#workarea .block_support .block_support_content .anim_block .anim_block_ul{
	display:block;
	padding-left:40px;
	padding-right:10px;
	line-height:1.3;
}
#workarea .block_support .block_support_content .anim_block .anim_block_text{
	display:block;
	padding-left:30px;
	padding-right:20px;
	line-height:1.3;
}
#workarea .block_support .button_all_container{
	text-align:center;
	margin-top:25px;
	display:none;
}
#workarea .block_support .button_all{
	width: 115px;
    height: 38px;
    background-color: #0065b3;
    border-radius: 20px;
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    text-align: center;
    outline: none;
    cursor: pointer;
   	border: none;
}
#workarea .block_support .button_all:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
@media (max-width: 1199px) {
	#workarea .block_support .block_support_container{
		justify-content:center;
	}
	#workarea .block_support .block_support_content{
		width:40%;
	}
}
@media (max-width: 991px) {
	#workarea .block_support .block_support_content{
		width:46%;
		margin: 10px;
	}
	#workarea .block_support .block_title h3{
		font-size:20px;
		text-align:center;
	}
	#workarea .block_support .block_support_content .content_text{
		font-size:16px;
	}
	#workarea .block_support .block_support_content .anim_block{
		font-size:13px;
	}
}
@media (max-width: 660px) {
	#workarea .block_support .block_support_content{
		width:100%;
		max-width:400px;
		margin: 0 0 20px;
	}
	#workarea .block_support .button_all_container{
		display:block;
	}
}
@media (max-width: 500px) {
	#workarea .block_support .block_support_container{
		padding:0 15px;
	}
	#workarea .block_support{
		padding:0 0 30px;
		margin:0 -10px;
	}
	#workarea .block_support .block_title .text{
		font-size:15px;
	}
	#workarea .block_support .button_all_container{
		margin-top:10px;
	}
}
