.block_img_partner {
    margin-bottom: 110px;
}

.partner_plus {
    margin: 70px 0 50px 0;
    float: left;
    width: 100%;
}

.partner_plus h2 {
    background: url(/images/index/pluse_title.jpg) 0 0 no-repeat;
    color: #fff !important;
    font-size: 23px;
    text-transform: none !important;
    padding: 17px 20px !important;
    display: block;
    width: 100%;
    text-align: left !important;
    background-size: cover;
}

.partner_plus .partner_plus_ul {
    margin: 15px 0 30px 0;
    width: 100%;
    list-style-type: none;
    padding: 0;
}

.partner_plus .partner_plus_ul li {
    padding: 10px 0 0 20px;
    display: flex;
    align-items: baseline;
    width: 93%;
}

.partner_plus .partner_plus_ul li:before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #0065b3;
    margin-right: 25px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}

.partner_plus .partner_plus_li {
    width: 100%;
}

.partner_plus .partner_plus_ul li:hover {
    background-color: #e7e8ea;
}

.partner_plus .partner_plus_ul .partner_plus_blue {
    font-size: 17px;
    color: #0065b3;
    display: block;
    margin-bottom: 5px;
    padding-right: 20px;
}

.partner_plus .partner_plus_ul .partner_plus_grey {
    font-size: 15px;
    color: #2d2c2e;
    display: block;
    padding-bottom: 12px;
    padding-right: 20px;
}

.partner_plus .border_bottom {
    border-bottom: 1px solid #bebec0;
}

.partner_info--small {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: -40px auto 0;
}

.partner_info .partner_info_punct {
    background-color: #0065b3;
    color: #fff;
    border-radius: 40px;
    text-align: center;
    line-height: 1;
    min-height: 83px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
}

.partner_info .partner_info_punct_number {
    font-size: 40px;
}

.partner_info .partner_info_punct_text {
    font-size: 13px;
}

.partner_info_punct_background1 {
    background: url(/images/include/icon_partner1.svg) 5px 50% no-repeat;
}

.partner_info_punct_background2 {
    background: url(/images/include/icon_partner2.svg) 5px 50% no-repeat;
}

.partner_info_punct_background3 {
    background: url(/images/include/icon_partner3.svg) 5px 50% no-repeat;
}

.partner_info .partner_info_punct_padding1 {
    padding: 15px 45px 15px 100px;
}

.partner_info .partner_info_punct_padding2 {
    padding: 15px 55px 15px 105px;
}

.partner_info .partner_info_punct_padding3 {
    padding: 15px 25px 15px 85px;
}

.block_partner_link {
    margin-bottom: 60px;
}

.block_partner_link .partner_link {
    width: 150px;
    height: 35px;
    margin: 0 auto;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding: 0;
    outline: none;
    cursor: pointer;
    border: 0;
	line-height: 35px;
}

.block_partner_link .partner_link:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
#forma_partner{
	display:none;
	max-width:380px;
	background-color:#fff;
	padding:30px 20px;
	text-align:center;
}
#forma_partner .info{
	color:#2d2c2e;
	font-size:14px;
	text-align:center;
	line-height:1.4;
	padding-bottom:5px;
}

@media (max-width: 991px) {
    .partner_info {
        margin-top: -93px;
    }

    .partner_info .partner_info_punct {
        margin: 5px 10px;
        flex-grow: 1;
    }

    .partner_info .partner_info_punct_number {
        font-size: 30px;
    }
}

@media (max-width: 500px) {
    .partner_info {
        margin-top: 20px;
        flex-direction: column;
    }

    .block_img_partner {
        display: none;
    }

    .partner_plus {
        margin: 20px 0;
    }

    .partner_plus .partner_plus_ul {
        margin: 15px 0;
    }

    .partner_plus .partner_plus_ul .partner_plus_blue,
    .partner_plus .partner_plus_ul .partner_plus_gray {
        padding-right: 10px;
    }

    .partner_plus .partner_plus_ul li {
        width: 97%;
        padding-left: 5px;
    }

    .partner_plus .partner_plus_ul li:before {
        margin-right: 15px;
    }
}
