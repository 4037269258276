.slider_equipment .buttom_prev{
    left: -20px;
}
.slider_equipment .buttom_next{
    right: -20px;
}
.slider_equipment .buttom_prev, .buttom_next {
    line-height: 0;
    position: absolute;
    top: 15%;
    display: block;
    width: 20px;
    height: 50%;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background-color: transparent;
}
.slider_equipment .block_slider a.gallery, .slider_equipment_index .block_slider a.gallery{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:220px;
    padding:0 15px;
}
.slider_equipment{
	height:220px;
}
.slider_equipment .block_slider img{
	max-height:220px;
    height: auto;
    max-width: 100%;
	margin: 0 auto;
}
.slider_equipment .block_slider{
	height:220px;
	text-align:center;
}
.slider_equipment .block_slider:focus{
	outline: none;
}
#slick_dots .slick-dots {
    position: relative;
    width: 100%;
	height:auto;
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
	bottom:0;
	text-align:left;
	display:flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-items: stretch;
	align-items: stretch;
}
#slick_dots .slick-dots li {
    position: relative;
    display: flex;
    align-items: center;
    width: 100px !important;
    height: auto;
    margin: 0 5px;
    padding: 2px;
    cursor: pointer;
	border:1px solid #fff;
	justify-content: center;
}
#slick_dots .slick-dots li.slick-active{
	border:1px solid #bdbdbd;
}
#slick_dots .slick-dots li button:before {
    font-family: '';
    font-size: 6px;
    line-height: 0px;
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    content: '';
    text-align: left;
    opacity: 1;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#slick_dots img{
    height: auto;
    max-width: 100%;
	margin: 0 auto;
	outline: none;
	border:1px solid #fff;
}
#slick_dots .slick-dots li .button_dots{
	width:100%;
	height:auto;
	padding:0;
}

