.slick-slider .slick-slide img {
	min-height:auto;
}
.slick-slider .slick-slide a{
	outline:none;
}
.slick-slider .slick-slide:focus{
	outline:none;
}
.slick-slider .slick-dots li button::before {
    font-size: 16px;
    color: #bdbec0;
	opacity: 1 !important;
}
.slick-slider .slick-dots li.slick-active button:before {
	color: #0065b3;
}
.slick-slider .slick-dots li{
	margin: 0;
	padding: 5px;
}
.slick-slider .buttom_prev{
    left: -30px;
}
.slick-slider .buttom_next{
    right: -30px;
}
.slick-slider .buttom_prev, .slick-slider .buttom_next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 50%;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background-color: transparent;
}
.fancybox-custom .fancybox-skin {
	box-shadow: 0 0 50px #222;
}
