#workarea .block_results{
	padding:0 30px 20px 30px;
}
#workarea .block_results .block_results_container{
	padding:0;
	text-align:center;
	color:#2d2c2e;
	font-size:14px;
	display:flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
#workarea .block_results .block_text_container{
	padding:40px 0 0 0;
	text-align:center;
	color:#2d2c2e;
	font-size:14px;
}

#workarea .block_results .block_results_container .results_company{
	padding:25px 25px 0 25px;
	text-align:left;
	width:33.3%;
}
#workarea .block_results .block_results_container .results_company img{
	max-width:200px;
}
#workarea .block_results .block_results_container .result_text{
	margin:0;
	padding:20px 0px 25px 0;
	display:flex;
	height:152px;
}
#workarea .block_results .block_results_container .border_bottom{
	border-bottom:2px solid #d5d6d7;
}
#workarea .block_results .block_results_container .result_text .text_percent{
	width:105px;
	height:105px;
	flex-shrink:0;
	color:#2d2c2e;
	font-size:14px;
	text-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
#workarea .block_results .block_results_container .result_text .text_percent1{
	width:65px;
	height:105px;
	flex-shrink:0;
}
#workarea .block_results .block_results_container .result_text .percent_background1{
	background:url(/images/index/result_icon9.svg) -15px 0 no-repeat;
}
#workarea .block_results .block_results_container .result_text .percent_background2{
	background:url(/images/index/result_icon2.svg) 0 0 no-repeat;
	background-size:100%;
}
#workarea .block_results .block_results_container .result_text .percent_background3{
	background:url(/images/index/result_icon3.svg) 0 0 no-repeat;
	background-size:100%;
}
#workarea .block_results .block_results_container .result_text .percent_background4{
	background:url(/images/index/result_icon4.svg) 0 0 no-repeat;
	background-size:100%;
}
#workarea .block_results .block_results_container .result_text .percent_background5{
	background:url(/images/index/result_icon5.svg) 0 0 no-repeat;
	background-size:100%;
}
#workarea .block_results .block_results_container .result_text .percent_background6{
	background:url(/images/index/result_icon6.svg) 0 0 no-repeat;
	background-size:100%;
}
#workarea .block_results .block_results_container .result_text .percent_background7{
	background:url(/images/index/result_icon7.svg) 0 0 no-repeat;
	background-size:100%;
}
#workarea .block_results .block_results_container .result_text .percent_background8{
	background:url(/images/index/result_icon8.svg) 0 0 no-repeat;
	background-size:100%;
}
#workarea .block_results .block_results_container .result_text .text_content{
	display:flex;
	align-items:center;
	line-height:1.2;
	font-size:14px;
	padding-left:13px;
}
#workarea .block_results .block_results_container .result_text .text_content .text_content_number{
	font-size:23px;
}
#workarea .block_results .block_results_container .result_text .text_percent .number_percent{
	font-size:35px;
}
#workarea .block_results .button_all_container{
	text-align:center;
	margin-top:40px;
	display:none;
}
#workarea .block_results .button_all{
	width: 115px;
    height: 38px;
    background-color: #0065b3;
    border-radius: 20px;
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    text-align: center;
    outline: none;
    cursor: pointer;
   	border: none;
}
#workarea .block_results .button_all:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
@media (max-width: 991px) {
	#workarea .block_results{
		padding:0 0 20px;
	}
	#workarea .block_results .block_results_container .results_company{
		width:50%;
	}
	#workarea h3 br{
		display:none;
	}
}
@media (max-width: 660px) {
	#workarea .block_results .block_results_container .results_company{
		width:100%;
		max-width:350px;
		padding: 20px 20px 0 20px;
	}
	#workarea .block_results .button_all_container{
		display:block;
	}
}
@media (max-width: 500px) {
	#workarea h3{
		font-size:20px;
		padding:0 10px;
	}
	#workarea .block_white_border{
		padding-top:25px;
	}

	#workarea .block_results .logo_img{
		text-align:center;
	}
	#workarea .block_results .block_results_container .result_text{
		flex-direction:column;
		height:auto;
		padding:10px 0px 15px 0;
	}
	#workarea .block_results .block_results_container .result_text .text_percent,
	#workarea .block_results .block_results_container .result_text .text_percent1{
		margin:0 auto;
	}
	#workarea .block_results .block_results_container .result_text .text_content{
		text-align:center;
		display:block;
		margin-top:15px;
	}
	#workarea .block_results .block_results_container .results_company{
		padding:15px 0 0;
	}

}