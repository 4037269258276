.page--software-365 {
  #workarea .cloud_system_info {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
  }
  #workarea .cloud_system_info .cloud_system_logo {
    width: 150px;
    margin: 0 30px;
    flex-shrink: 0;
  }
  #workarea .cloud_system_data {
    width: 100%;
    max-width: 750px;
    padding-top: 40px;
    font-size: 17px;
  }
  #workarea .cloud_system_data .cloud_system_statistics {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 50px;
  }
  #workarea .cloud_system_statistics .statistics_item {
    background-color: #0065b3;
    color: #fff;
    width: 49%;
    margin-bottom: 20px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 5px 20px;
    min-height: 60px;
  }
  #workarea .cloud_system_statistics .statistics_item:last-child {
    flex-grow: 1;
  }
  #workarea .cloud_system_statistics .statistics_item_number {
    font-size: 50px;
    margin-right: 25px;
  }
  #workarea .cloud_system_statistics .statistics_item_number .percent {
    font-size: 35px;
  }
  #workarea .server_scheme {
    background-color: #fff;
    padding: 30px 35px;
    display: flex;
    align-items: center;
  }
  #workarea .server_scheme .server_scheme_items {
    flex-grow: 1;
    max-width: 400px;
    padding-left: 5px;
  }
  #workarea .server_scheme .server_scheme_item {
    font-size: 17px;
    padding: 10px 0;
  }
  #workarea .server_scheme .server_scheme_items hr {
    border-top: 1px solid #bebec0;
    margin: 10px 0;
  }
  #workarea .server_scheme .server_scheme_img {
    width: 100%;
    max-width: 400px;
    margin-left: 50px;
  }
  #workarea .software_block {
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
  #workarea .software_item {
    padding: 0 10px 20px 10px;
    width: 46%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    margin-bottom: 20px;
  }
  #workarea .software_item .software_img {
    height: 150px;
    border-bottom: 1px solid #bdbdbd;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #workarea .software_item .software_img img {
    max-width: 100%;
  }
  #workarea .software_item .software_title {
    color: #0066b3;
  }
  #workarea .software_item .software_text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px;
  }
  #workarea .software_item .software_content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  #workarea .software_item .software_bg {
    position: absolute;
    z-index: 1;
    top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    transition: all 200ms ease-out 0ms;
  }
  #workarea .software_item:hover .software_bg {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
  }
  #workarea .software_item .software_list_plus {
    min-height: 90px;
    margin-top: 20px;
  }

  #workarea .software_item .block_button_soft {
    margin: auto 0 0;
    padding-top: 20px;
  }
  #workarea .account_data_block {
    background-color: #fff;
    padding: 20px;
    margin-top: -7px;
    z-index: 1;
    position: relative;
    display: none;
  }
  #workarea .account_data_block .account_data {
    padding: 20px 15px;
    border-top: 1px solid #bebec0;
    border-bottom: 1px solid #bebec0;
    text-align: center;
    font-size: 17px;
    max-width: 487px;
    margin: 50px auto;
  }
  #workarea .account_data .account_item {
    margin-bottom: 5px;
  }
  #workarea .account_item .account_item_title {
    color: #0066b3;
  }
  #workarea .account_data_block .account_note {
    margin-top: 25px;
  }
  #workarea .account_btn_block {
    width: 100%;
    position: absolute;
    text-align: center;
    left: 0;
    bottom: -15px;
    z-index: 5;
  }
  #workarea .account_btn {
    width: 295px;
    height: 34px;
    background: #0065b3;
    color: #fff;
    border-radius: 30px;
    border: 0;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
  }
  #workarea .account_btn:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  #workarea .solutions_container_soft {
    margin: 40px 0;
  }
  #workarea .articles_block {
    padding: 30px 35px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #workarea .articles_wrapper {
    width: 47%;
    flex-shrink: 0;
  }
  #workarea .articles_group_title {
    text-transform: uppercase;
    font-size: 17px;
    border-style: solid;
    border-width: 0px 0px 1px;
    border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    margin-bottom: 20px;
  }
  #workarea .articles_list {
    max-width: 405px;
  }
  #workarea .articles_group .article {
    padding: 10px 0;
    border-bottom: 1px solid #c8c9cc;
  }
  #workarea .articles_group .article a {
    font-size: 17px;
    color: #0066b3 !important;
  }
  #workarea .list {
    padding: 0;
  }
  #workarea .list .list_item {
    display: flex;
    align-items: baseline;
  }
  #workarea .list .list_item:before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #0065b3;
    margin-right: 25px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
  }
  #workarea .block_border {
    width: 100%;
    height: 7px;
    background: linear-gradient(to bottom, #eaeaeb, transparent);
    z-index: 2;
    position: relative;
  }
  #forma_client_365{
    display:none;
    max-width:380px;
    background-color:#fff;
    padding:30px 20px;
    text-align:center;
  }
  #forma_client_365 .info{
    color:#2d2c2e;
    font-size:14px;
    text-align:center;
    line-height:1.4;
    padding-bottom:5px;
  }
  @media (max-width: 1199px) {
    #workarea .cloud_system_data {padding-top: 30px;}
    #workarea .cloud_system_statistics .statistics_item_number {font-size: 35px;}
  }
  @media (max-width: 991px) {
    #workarea .cloud_system_info {flex-direction: column; align-items: center;}
    #workarea .cloud_system_data .cloud_system_statistics {padding-right: 0; max-width: 530px; margin: 30px auto 0;}
    #workarea .server_scheme {flex-direction: column;}
    #workarea .server_scheme .server_scheme_img { order: -1; margin: 0 0 20px; max-width: 300px;}
    #workarea .server_scheme .server_scheme_items {max-width: none; width: 100%;}
    #workarea .software_block {flex-direction: column;}
    #workarea .software_item { width: 100%;}
    #workarea .software_item .software_list_plus {min-height: 0;}
    #workarea .block_system_plus .block_button_soft {margin-top: 30px;}
    #workarea .articles_wrapper {width: 100%;}
    #workarea .articles_list {max-width: none;}
    #workarea .articles_list .block_button_soft {margin-top: 30px;}
    #workarea .articles_group .article { border-bottom: 0;}
  }
  @media (max-width: 660px) {
    #workarea .cloud_system_statistics .statistics_item {width: 100%;justify-content: center;}
  }
  @media (max-width: 500px) {
    #workarea .cloud_system_data {font-size: 15px;padding-top: 20px;}
    #workarea .cloud_system_statistics .statistics_item {flex-direction: column;text-align: center;}
    #workarea .cloud_system_statistics .statistics_item_number { margin: 0 0 5px; }
    #workarea .cloud_system_statistics .statistics_item_number,
    #workarea .cloud_system_statistics .statistics_item_number .percent {font-size: 30px;}
    #workarea .server_scheme {padding: 15px;}
    #workarea .list .list_item {font-size: 15px;}
    #workarea .software_block {padding: 15px;}
    #workarea .software_item .block_button_soft {flex-direction: column; align-items: center;}
    #workarea .software_item .block_button_soft_info { margin: 10px 0 0;}
    #workarea .account_data_block .account_data {font-size: 15px;}
    #workarea .account_data .account_item { display: flex;flex-direction: column;}
    #workarea .articles_block {padding: 15px; }
    #workarea .articles_group .article a{font-size:15px;}
    #workarea .account_btn { width: 250px;}
  }
}
