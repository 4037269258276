.its-content {
  p {
    margin-bottom: 10px;
  }

  ul {
    padding-left: 20px;
    list-style-type: none;
  }

  ul li {
    display: flex;
    align-items: baseline;
  }

  ul li::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0065b3;
    margin-right: 15px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
  }
}
