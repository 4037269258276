#workarea .section_wrapper h3 {
    margin-bottom: 45px;
}

#workarea .video_block {
    display: flex;
    flex-direction: column;
}

.video_block .video_wrapper {
    display: flex;
    flex-wrap: wrap;
}

.video_block .video_wrapper_hidden {
    width: 100%;
    position: relative;
    display: none;
}

.video_block .video_elem {
    padding: 0 15px 40px;
    width: 33.3%;
}

.video_block .video_elem .video {
    width: 100%;
    background-color: #d1d2d4;
    margin-bottom: 20px;
    border: 1px solid #d1d2d4;
}

.block_training_button{
    background-color: #fff;
}

.block_training_button .video_btn {
    width: 130px;
    height: 38px;
    background-color: #0065b3;
    border-radius: 20px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin: 0px auto -28px auto;
    border: none;
    z-index: 100;
    position: relative;
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.block_training_button .video_btn:hover {
    box-shadow: none;
}

#workarea .training_options_block {
    margin-bottom: 30px;
}

#workarea .training_options_block h3 {
    margin-bottom: 20px;
}

.training_options_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.training_options_block .training_option {
    width: 47%;
    padding: 20px 20px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.training_options_block .option_content {
    position: relative;
    z-index: 10;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.training_option .option_logo {
    min-height: 107px;
    margin-bottom: 15px;
    border-bottom: 1px solid #bebec0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.training_option .option_logo img {
    max-width: 100%;
}

.training_option .option_name {
    font-size: 17px;
    margin-bottom: 15px;
    color: #0066b3;
}

.training_option .option_description {
    margin-bottom: 25px;
}

.training_option .option_description ul {
    padding: 0;
    list-style-type: none;
}

.training_option .option_description li {
    display: flex;
    align-items: baseline;
    margin-top: 10px;
}

.training_option .option_description li::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0065b3;
    margin-right: 10px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}

.training_option .option_shadow {
    position: absolute;
    z-index: 1;
    top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    background-color: transparent;
    transition: all 200ms ease-out 0ms;
}

.training_options_block .training_option:hover .option_shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
}

.training_option .buttons_container {
    text-align: center;
    margin-top: auto;
}

.training_option .buttons_container .support_btn:not(:last-child) {
    margin-right: 20px;
}

.block_training_button .block_border {
    width: 100%;
    height: 5px;
    background: none!important;
    z-index: 10;
    position: relative;
}

@media (max-width: 1199px) {
    .training_options_block .training_option {
        width: 49%;
    }
}

@media (max-width: 991px) {
    .training_options_block .training_option {
        width: 100%;
    }

    .video_block .video_wrapper {
        flex-direction: column;
        align-items: center;
    }

    .video_block .video_elem {
        width: 100%;
        max-width: 325px;
    }
}

@media (max-width: 500px) {
    .training_option .buttons_container {
        display: flex;
        flex-direction: column;
        align-items:center;
    }

    .training_option .buttons_container .support_btn:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
