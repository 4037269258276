#workarea .contacts {
    display: table;
    font-size: 16px;
    padding: 12px 16px;
}

#workarea .contacts .contact {
    display: table-row;
}
#workarea .contacts .contact .tel{
	font-size:16px;
	color:#2d2c2e;
	text-decoration:none;
}
#workarea .contact span {
    display: table-cell;
    padding: 12px 0;
    border-bottom: 2px solid #f3f3f5;
}

#workarea .contact span:nth-child(2) {
    white-space: nowrap;
}

#workarea .contact:last-child span {
    border-bottom: 0;
}

#workarea .contact .contact_title {
    display: block;
    color: #0066b3;
    padding-right: 35px;
    font-style: normal;
}

#workarea .contact .contact_title_detail {
    font-size: 14px;
    font-style: normal;
}

#workarea .block_system_ul_question li {
    width: 60%;
}

#workarea .block_system_schema h4 {
    text-transform: uppercase;
    font-size: 19px;
}

#workarea .block_system_schema .schema_img_block {
    display: flex;
    justify-content: space-between;
}
#workarea .block_system_schema .schema_img_block_text{
	text-align:center;
	padding:10px 0 15px 0;
	font-size:16px;
}

#workarea .schema_img_block img {
    width: 100%;
    max-width: 700px;
}

#workarea .schema_img_block img.pie_chart {
    max-width: 350px;
}

#workarea .service_list {
    margin-bottom: 20px;
}

#workarea .service_list .service_img_block {
    display: flex;
    max-width: 850px;
    align-items: center;
    font-size: 17px;
    padding: 30px 20px;
}

#workarea .service_img_block img {
    border-radius: 50%;
    margin-right: 40px;
}

#workarea .sevice_scheme_block {
    margin: 20px 0;
}

#workarea .service_scheme {
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    height: 325px;
	background: url(/images/support/tech-service_icon1.svg) 50% 50% no-repeat;
}

#workarea .service_scheme .service_scheme_elem_group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

#workarea .service_scheme .scheme_elem {
    display: flex;
    align-items: center;
}

#workarea .service_scheme .scheme_elem_text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    width: 245px;
    height: 57px;
    border-radius: 30px;
    background: linear-gradient(to right, #e6884a, #f4bb48);
    margin-left: 15px;
}

#workarea .service_scheme .scheme_elem_num {
    font-size: 50px;
}

#workarea .service_scheme .scheme_elem_num .percent {
    font-size: 35px;
}

#workarea .sevice_scheme_block p {
    margin-bottom: 10px;
}

@media (max-width: 1199px) {
    #workarea .block_system_schema .schema_img_block {
        flex-direction: column;
        align-items: center;
    }

    #workarea .schema_img_block img {
        margin-bottom: 20px;
    }
	#workarea .service_scheme {
		background: none;
	}

}

@media (max-width: 991px) {
    #workarea .block_system_ul_question li {
        width: 93%;
    }

    #workarea .service_scheme {
        flex-direction: column;
        height: auto;
        align-items: center;
    }
}

@media (max-width: 660px) {
    #workarea .contacts {
        width: 100%;
    }

    #workarea .service_list .service_img_block {
        flex-direction: column;
        align-items: center;
    }

    #workarea .service_list .service_img_block .img_legend {
        width: 100%;
    }

    #workarea .service_list .service_img_block img {
        margin-bottom: 20px;
        margin-right: 0;
        width: 180px;
    }
}

@media (max-width: 500px) {
    #workarea .block_system_ul_question li {
        width: 97%;
    }

    #workarea .section_wrapper:not(:nth-child(4)) {
        margin-bottom: 20px;
    }

    #workarea .contacts .contact {
        display: block;
    }

    #workarea .contact .contact_title {
        padding-right: 0;
    }

    #workarea .contacts .contact span {
        display: block;
        border: none;
        padding: 7px 0;
    }

    #workarea .contacts .contact:not(:last-child) {
        border-bottom: 2px solid #f3f3f5;
    }

    #workarea .service_list .service_img_block .img_legend {
        font-size: 15px;
    }

    #workarea .service_scheme {
        padding: 20px 0;
    }

    #workarea .service_scheme .scheme_elem {
        margin: 5px 0;
    }

    #workarea .service_scheme .scheme_elem_num {
        font-size: 30px;
    }

    #workarea .service_scheme .scheme_elem_text {
        width: 205px;
    }
}
