#workarea .block_selections{
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
}
#workarea .block_selections .selections_container{
	width:33%;
	padding:0 45px;
	text-align:center;
}
#workarea .block_selections .selections_container .selections_container_img{
	min-height:150px;
	justify-content: center;
	align-items: center;
	display:flex;
}
#workarea .block_selections .selections_container img{
	max-width:100%;
}
#workarea .block_selections .selections_container .selections_container_img.border{
	border-bottom: 2px solid #e7e7e8;
}
#workarea .block_selections .selections_container .selections_container_text{
	color:#2d2c2e;
	font-size:17px;
	line-height: 1.22;
	padding:20px 5px;
}
#workarea .block_selections .selections_container .selections_container_img6{
	min-height:270px;
	height:100%;
	justify-content: center;
	align-items: center;
	display:flex;
}
@media only screen and (max-width: 1199px){
	#workarea .block_selections .selections_container{
		padding:0 20px;
	}
}
@media only screen and (max-width: 991px){
	#workarea .block_selections .selections_container{
		width:50%;
	}
	#workarea .block_selections .selections_container:nth-child(3){
		order:1;
	}
	#workarea .block_selections .selections_container:last-child{
		order:2;
	}
}
@media only screen and (max-width: 660px){
	#workarea .block_selections .selections_container{
		width:100%;
		max-width:350px;
	}
	#workarea .block_selections .selections_container .selections_container_img{
		min-height:0;
		padding:20px;
	}
	#workarea .block_selections .selections_container .selections_container_img6{
		min-height:0;
	}
}
@media only screen and (max-width: 500px){
	#workarea .block_selections .selections_container .selections_container_text{
		font-size:15px;
	}
}