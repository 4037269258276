#workarea .news_container {
    padding: 0;
}

#workarea .block_buttom {
    padding: 0 0 50px 0;
}

#workarea .block_buttom .text {
    padding: 0 0 40px 0;
    font-size: 17px;
    color: #0066b3;
    text-align: center;
}

#workarea .block_buttom .number_link {
    width: 150px;
    height: 35px;
    margin: 0;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding-top: 6px;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
}

/* Стили вкладок */
#Tabs_news {
    border-bottom: 0;
	border-bottom: 0;
    clear: both;
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

#Tabs_news > li.active, .nav-tabs > li.active:hover, .nav-tabs > li.active:focus {
    color: #0066b3;
    cursor: pointer;
    background-color: #fff;
    border: 0;
    text-decoration: none;
}

#Tabs_news > li {
    margin-right: 10px;
    line-height: 1.42857143;
    border: 0;
    border-radius: 0;
    background-color: #0066b3;
    font-size: 17px;
    color: #fff;
    min-width: 175px;
    text-align: center;
    cursor: pointer;
}

#Tabs_news.nav-tabs > li {
    position: relative;
    display: block;
    padding: 20px 15px;
}

#Tabs_news > li:hover {
    border-color: #fff;
}

#Tabs_news.nav-tabs > li:hover, .nav-tabs > li:focus {
    text-decoration: none;
    background-color: #fff;
    color: #0066b3;
}

#TabContent_news {
    background-color: #fff;
    padding: 30px 40px;
    border: 0;
}

/* Стили блока с границей */
#workarea .block_white_border {
    background-color: #fff;
    padding: 45px 0 25px 0;
    border-bottom: 1px solid #eaeaeb;
}

#workarea .block_border {
    width: 100%;
    height: 7px;
    background: linear-gradient(to bottom, #eaeaeb, #fefefe);
}

/* Стили блока без границы */
#workarea .block_white {
    background-color: #fff;
    padding: 40px 15px 30px 15px;
}

/* Стили блока - Преимущества */
#workarea .block_pluse {
    margin: 30px 0 32px 0;
    background-color: #fff;
    padding: 20px;
}

#workarea .block_pluse h4 {
    font-size: 25px;
    color: #fff;
    padding: 17px 25px;
    background: url(/images/index/pluse_title.jpg) 0 0 no-repeat;
    margin-bottom: 23px;
    background-size: cover;
}

#workarea .block_pluse .pluse_ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

#workarea .block_pluse .pluse_ul li {
    padding: 0 20px;
    width: 50%;
    display: flex;
    align-items: baseline;
}

#workarea .block_pluse .pluse_ul li:before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #0065b3;
    margin-right: 25px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}

#workarea .block_pluse .pluse_ul .border span {
    border-bottom: 1px solid #bcbdc0;
}

#workarea .block_pluse .pluse_ul span {
    color: #58595b;
    font-size: 17px;
    padding: 12px 0;
    width: 100%;
}

#workarea .clear {
    clear: both;
}

#workarea .news_container .display_tabs{
	display:none;
}

@media (max-width: 991px) {
    #workarea .block_pluse .pluse_ul li {
        width: 100%;
    }

    #workarea .block_pluse .pluse_ul li:not(:last-child) span {
        border-bottom: 1px solid #bcbdc0;
    }
	#Tabs_news.nav-tabs > li {
		padding: 15px 10px;
	}
	#Tabs_news > li {
		min-width: 160px;
		margin-right:0;
	}
	#Tabs_news {
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}
}

@media (max-width: 768px) {
	#workarea .news_container .nav-tabs-top{
		margin-bottom: 25px;
	}
	#workarea .news_container .nav-tabs-bottom{
		margin-top: 25px;
	}
	#TabContent_news {
		padding: 10px 20px 30px 20px;
	}
	#Tabs_news{
		width:100%;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	#Tabs_news.nav-tabs > li {
		padding: 12px 5px;
		border-radius: 9px;
		margin-top:7px;
		margin-left:0;
		margin-bottom:7px;
		margin-right:5px;
	}
	#Tabs_news > li {
		margin-right: 0;
		font-size: 15px;
		min-width: 150px;
	}
	#Tabs_news > li:last-child {
		margin-right:0;
	}
	#workarea .news_container .display_tabs{
		display:block;
	}

}
@media (max-width: 670px) {
	#Tabs_news.nav-tabs > li {
		margin-top: 7px;
		margin-left: 0;
		margin-bottom: 7px;
		margin-right: 0px;
		width: 48%;
	}
	#Tabs_news{
		-webkit-justify-content:  space-between;
		justify-content:  space-between;
	}
	#Tabs_news > li {
		min-width: 140px;
	}
}

@media (max-width: 500px) {
    #workarea .title_top {
        padding: 20px 15px 20px;
        font-size: 15px;
    }

    #workarea .title_top .size_17px {
        font-size: 15px;
    }

    #workarea .margin_bottom {
        margin-bottom: 0;
    }

    #workarea .block_pluse {
        padding: 15px;
        margin: 20px 0;
    }

    #workarea .block_pluse h4 {
        margin-bottom: 15px;
    }

    #workarea .block_pluse .pluse_ul li {
        padding: 0 10px 0 5px;
    }

    #workarea .block_pluse .pluse_ul span {
        font-size: 15px;
    }

    #workarea .block_pluse .pluse_ul li:before {
        margin-right: 15px;
    }
	#TabContent_news {
		padding: 10px 15px 30px 15px;
	}
}
