.slider_container{
	display:flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

#workarea #reports_list2{display:block; width:100%;}
#workarea #reports_list2 .slick-slide img {
	display:block; 
	width:100%;
	opacity:0.4;
}
#workarea #reports_list2 .slick-slide.slick-center img {
	opacity:1;
}
#workarea #reports_list2 .block_slider_content_img{
	padding: 10px 20px 20px 20px;
    display: flex;
    height: 400px;
    overflow: hidden;
    position: relative;
}
#workarea #reports_list2 .slick-slide.slick-center .slider_items_text {
	visibility:visible;
}
#workarea #reports_list2 .slider_items_text{
	display:block;
	text-align:center;
	font-size:16px;
	width:100%;
	padding:5px 10px 5px 10px;
	visibility:hidden;
	min-height:70px;
	line-height: 1.3;
}
#workarea #reports_list2 .slick-slide.slick-center .slider_items_text_title {
	visibility:visible;
}
#workarea #reports_list2 .slider_items_text_title{
	display:block;
	text-align:center;
	font-size:16px;
	width:100%;
	padding:15px 10px 5px 10px;
	visibility:hidden;
	font-family:"RobotoMedium";
}
#workarea #reports_list2 .slick-slider .buttom_prev{
    left: 190px;
    z-index: 100;
}
#workarea #reports_list2 .slick-slider .buttom_next{
    right: 190px;
    z-index: 100;
}
#workarea #reports_list2 .slick-dots {
    bottom: -60px;
}

@media (max-width: 1199px) {
	#workarea #reports_list2 .slick-slider .buttom_prev{
		left: 90px;
		z-index: 100;
	}
	#workarea #reports_list2 .slick-slider .buttom_next{
		right: 90px;
		z-index: 100;
	}

}
@media (max-width: 991px) {
	#workarea #reports_list2{
		display:block;
		width:100%;
	}
	#workarea .slider_reports2 .block_slider_content1 img {
		width: 100%;
		min-height: auto;
		margin: 0 auto;
	}
	#workarea #reports_list2 .slick-slide img {
		opacity:1;
	}
	#workarea #reports_list2 .slick-slider .buttom_prev{
		left: -20px;
	}
	#workarea #reports_list2 .slick-slider .buttom_next{
		right: -20px;
	}
	#workarea #reports_list2 .slider_items_text{
		visibility:visible;
	}
	#workarea #reports_list2 .slider_items_text_title{
		visibility:visible;
	}
	#workarea #reports_list2 .block_slider_content_img{
		padding: 10px 20px;
		display: flex;
		align-items:flex-start;
		justify-content: center;
		height: auto;
		position: relative;
		border: 1px solid #dedede;
		height:300px;
		overflow:hidden;
	}
}
@media (max-width: 500px) {
	#workarea .slider_reports2{
		width:100%;
	}
	#workarea #reports_list2 .block_slider_content_img{
		padding: 10px;
	}
	#workarea #reports_list2 .slider_items_text{
		font-size:15px;
	}
	#workarea #reports_list2 .slider_items_text_title{
		font-size:15px;
	}
}