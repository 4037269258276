h1, h2, h3, h4, h5, h6 {
	font-family: "RobotoLight", Tahoma, sans-serif;
	font-weight: normal;
	margin:0;
	padding:0;
	color:#000;
	line-height:1.2;
}
h1, .h1{
	font-size:25px;
	padding-bottom:13px;
	color:#0066b3;
	font-weight:normal;
	border-style: solid;
    border-width: 0px 0px 2px;
    -moz-border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
    -webkit-border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
    -o-border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
    border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
	margin-bottom:13px;
}

h2{
  color: #0066b3;
  font-size: 20px;
  padding:20px 0;
  text-transform:none;
}
h3{
	color:#000;
	font-size:20px;
	padding:15px 0;
}
b, strong{
	font-family:"RobotoMedium", Tahoma, sans-serif;
	font-weight: 500;
}
blockquote {
  font-size: 15px;
}
.text-wysiwyg p {
    margin-bottom: 1em;
}

.text-wysiwyg  ul.pluse_ul {
    padding: 0 0 0 10px;
    list-style:none;
}

.text-wysiwyg  ul.pluse_ul li::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0065b3;
    margin-right: 15px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}
.text-wysiwyg table,.text-wysiwyg table td {
    border: solid 2px #0065b3;
    padding: 20px;
}

@media only screen and (max-width: 1200px){
    .container-fluid iframe {
        height: 300px;
        width: 100%;
        display: block;
        margin: auto;
        max-height: 100%;
    }
}

@media only screen and (max-width: 768px){
    .container-fluid iframe {
        height: 250px;
    }
}

@media only screen and (max-width: 580px){
    .container-fluid iframe {
        height: 220px;
    }
}

@media only screen and (max-width: 500px){
    .container-fluid iframe {
        height: 180px;
    }
}

@media only screen and (max-width: 400px){
    .container-fluid iframe {
        height: 150px;
    }
}
