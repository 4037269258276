.page--software {
  #workarea h1{

  }
  #workarea .container_h1{
    padding:40px 0;
  }
  #workarea .container_h1 .size_17px {
    font-size: 17px;
  }
  #workarea .container_h1_row{
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #workarea .container_h1 .container_h1_video.container_h1_col{
    width: 43%;
    position: relative;
    z-index: 10;
    padding:5px 0 0 0;
  }
  #workarea .container_h1 .container_h1_col{
    width: 55%;
  }
  #workarea .block_buttom{
    padding:50px 0;
  }
  #workarea .block_buttom .text{
    padding:0 0 40px 0;
    font-size:17px;
    color:#0066b3;
    text-align:center;
  }
  #workarea .block_buttom .number_link{
    width: 150px;
    height: 35px;
    margin: 0;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding-top: 6px;
    outline: none;
    cursor: pointer;
    margin:0 auto;
  }
  #workarea .block_buttom .number_link:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
  }
  #workarea .block_system_plus{
    background-color:#fff;
    padding:20px;
    margin-bottom:20px;
  }
  #workarea .block_system_plus h2{
    background: url(/images/index/pluse_title.jpg) 0 0 no-repeat;
    color: #fff !important;
    font-size: 23px;
    text-transform: none !important;
    padding: 17px 20px !important;
    display: block;
    width: 100%;
    text-align:left !important;
    background-size:cover;
  }
  .block_system_plus .block_system_plus_ul{
    margin:15px 0 10px 0;
    list-style-type:none;
    padding:0;
  }
  .block_system_plus .block_system_plus_ul li{
    padding:12px 0 0 20px;
    display: flex;
    align-items: baseline;
    width:93%;
  }
  .block_system_plus .block_system_plus_ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #0065b3;
    margin-right: 25px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
  }
  .block_system_plus .block_system_plus_ul li:hover{
    background-color:#e7e8ea;
  }
  .block_system_plus_ul .block_system_plus_grey{
    font-size:17px;
    color:#2d2c2e;
    display:block;
    padding-right:20px;
    padding-bottom: 12px;
    width:100%;
  }
  .block_system_plus_ul .border_bottom{
    border-bottom:1px solid #bebec0;
  }
  #workarea .system_info{
    width:100%;
    display:flex;
    max-width:660px;
    justify-content:space-around;
    margin:-40px auto 0;
  }
  .system_info .system_info_punct{
    background-color:#0065b3;
    color:#fff;
    border-radius:40px;
    text-align: center;
    line-height: 1;
    min-height:83px;
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  .system_info .system_info_punct_number{
    font-size:40px;
  }
  .system_info .system_info_punct_text{
    font-size:13px;
  }
  .system_info_punct_background1{
    background:url(/images/include/icon_partner1.svg) 5px 50% no-repeat;
  }
  .system_info_punct_background2{
    background:url(/images/include/icon_partner2.svg) 5px 50% no-repeat;
  }
  .system_info_punct_background3{
    background:url(/images/include/icon_partner3.svg) 5px 50% no-repeat;
  }
  .system_info .system_info_punct_padding1{
    padding:15px 45px 15px 100px;
  }
  .system_info .system_info_punct_padding2{
    padding:15px 55px 15px 105px;
  }
  .system_info .system_info_punct_padding3{
    padding:15px 25px 15px 85px;
  }
  #workarea .container_h3{
    margin:90px 0 40px 0;
  }
  #workarea .container_h3 h3{
    font-size: 23px;
    padding-bottom: 13px;
    color: #0066b3;
    font-weight: normal;
    border-style: solid;
    border-width: 0px 0px 1px;
    -moz-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    -webkit-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    -o-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    margin-bottom: 27px;
    text-align:center;
  }
  #workarea .container_h3 .size_17px{
    font-size:17px;
    padding:5px 40px 5px 40px;
  }
  #workarea .block_border_system{
    width: 100%;
    height: 7px;
    background: linear-gradient(to bottom, #eaeaeb, #fefefe);
  }
  #workarea .video_scout_container{
    max-width:500px;
    margin:0 auto;
    box-shadow: 0px 3px 9px 3px #bcbdc0;
  }
  #workarea .solutions_container{
    margin:40px 0 50px 0;
    width:100%;
  }
  #workarea .solutions_container_soft_title{
    color: #2d2c2e;
    text-align: center;
    font-size: 17px;
    padding: 22px 0 22px 0;
  }
  #workarea .solutions_container_soft_title .bold{
    font-family: "RobotoBold", Tahoma, sans-serif;
  }
  #workarea .block_button_soft{
    margin:50px 0 18px 0;
    display:flex;
    -webkit-justify-content: center;
    justify-content: center;
  }
  #workarea .block_button_soft .block_button_soft_info{
    margin:0 10px;
    width: 160px;
    height: 34px;
    line-height: 34px;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    border:0;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
  }
  #workarea .block_button_soft .block_button_soft_info.blue{
    background:#0065b3;
    color: #fff;
  }
  #workarea .block_button_soft .block_button_soft_info:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
  }
  #workarea .block_logo_soft{
    margin:30px 0 30px 0;
    display:flex;
    -webkit-align-items: stretch;
    align-items: stretch;
  }
  #workarea .block_logo_soft .block_logo_soft_img{
    margin:0 30px;
  }
  #workarea .block_logo_soft .block_logo_soft_text{
    color:#2d2c2e;
    font-size:17px;
    align-items: center;
    justify-content: center;
    display:flex;
  }
  #workarea .container_soft{
    padding-bottom:20px;
  }
  #workarea .container_soft h3{
    font-size:23px;
    text-align:center;
    color:#0065b3;
    text-transform:uppercase;
    padding:20px 0 30px 0;
  }
  #workarea .container_soft .block_logo_platform{
    margin:20px 0 30px 0;
    display:flex;
    align-items: center;
  }
  #workarea .container_soft .block_logo_platform .block_logo_platform_text{
    display:block;
    width:50%;
    padding:37px 20px 20px 20px;
    font-size: 17px;
    color: #2d2c2e;
  }
  #workarea .container_soft .block_logo_platform .block_logo_platform_text .block_logo_soft_img{
    padding-bottom:8px;
    max-width:400px;
    width:100%;
  }
  #workarea .container_soft .block_logo_platform .video_scout_soft{
    display:block;
    width:50%;
    margin:0 10px;
    max-width:500px;
  }
  #workarea .container_soft .block_logo_platform .video_scout_soft .video_scout_soft_container{
    box-shadow: 0px 3px 9px 3px #bcbdc0;
  }
  #workarea .container_soft h4{
    background-color: #0065b3;
    color: #fff;
    font-size: 23px;
    text-transform: none;
    padding: 18px 20px;
    display: block;
    width: 100%;
  }
  #workarea .container_soft_detail{
    width:100%;
    margin-bottom:8px;
  }
  #workarea .solutions_content_link{
    padding: 30px 35px 35px 35px;
    display: block;
    position: relative;
    width: 100%;
    color: #2d2c2e;
    font-size: 18px;
  }
  #workarea .solutions_content_link .solutions_row_link{
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: stretch;
    align-items: stretch;
  }
  #workarea .solutions_content_link .solutions_col_link{
    width: 48%;
  }
  #workarea .solutions_content_link .solutions_col_link a {
    display: block;
    padding-top: 20px;
    color: #0065b3;
    font-size: 18px;
    text-decoration: underline;
  }
  #workarea .solutions_content_link .solutions_col_link a:hover{
    text-decoration:none;
  }
  @media (max-width: 1199px) {
    #workarea .block_logo_soft .block_logo_soft_text br{display:none;}
  }
  @media (max-width: 991px) {
    #workarea .container_h3{margin-top:50px;}
    #workarea .container_soft .block_logo_platform{flex-wrap:wrap; justify-content:center;}
    #workarea .container_soft .block_logo_platform .block_logo_platform_text{width:100%; font-size:15px; padding: 15px 0 20px;}
    #workarea .container_soft .block_logo_platform .video_scout_soft{width:100%; max-width:350px; margin:0;}
    #workarea .container_soft .block_logo_platform .block_logo_platform_text .block_logo_soft_img{display:block; margin:0 auto;}
    #workarea .block_logo_soft{flex-direction:column;}
    #workarea .block_logo_soft .block_logo_soft_text{margin-top:20px;}
    #workarea .solutions_content_link .solutions_row_link{display:block;}
    #workarea .solutions_content_link .solutions_col_link{width:100%;}
    #workarea .software-list{padding:20px 0;}
    #workarea .block_logo_soft .block_logo_soft_img{width:150px;margin:0 auto;}
    #workarea .container_h1 .container_h1_video.container_h1_col{width: 100%; padding:30px 0 0 0;}
    #workarea .container_h1 .container_h1_col{width: 100%;}
  }
  @media (max-width: 660px) {
    #workarea .system_info{flex-direction:column; align-items:center; margin-top:-93px;}
    .system_info .system_info_punct{margin:5px 0; width:100%; max-width:335px;}
    .system_info .system_info_punct_number{font-size:30px;}
  }
  @media (max-width: 500px) {
    #workarea .container_h1{padding:20px 15px 30px;}
    #workarea .container_h1 .size_17px {font-size: 15px;}
    #workarea .block_system_plus{padding:15px;}
    .block_system_plus .block_system_plus_ul li {width:97%;}
    .block_system_plus_ul .block_system_plus_grey{padding-right:10px; font-size:15px;}
    .block_system_plus .block_system_plus_ul li {padding-left:5px;}
    .block_system_plus .block_system_plus_ul li:before {margin-right:15px;}
    #workarea .container_h3{margin-top:30px;}
    #workarea .container_h3 .size_17px{font-size:15px; padding:5px 15px;}
    #workarea .block_buttom .text{font-size:15px; padding: 0 5px 40px;}
    #workarea .block_system_plus_ul{padding-left:0;}
    #workarea .solutions_content_link{padding:15px 15px;}
    #workarea .solutions_content_link .solutions_col_link a{font-size:15px;}
    #workarea .block_logo_soft .block_logo_soft_text{font-size:15px;}
  }
}
