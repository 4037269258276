
#workarea .container_contacts {
    margin-bottom: 25px;
	display:flex;
}

#workarea .container_info {
    width: calc(100% - 420px);
    background-color: #fff;
    margin-right: 10px;
    padding: 22px;
}

#workarea .container_forma {
    width: 400px;
    background-color: #fff;
    margin-left: 10px;
    padding: 22px 30px;
    font-size: 17px;
    color: #58595b;
    text-align: center;
}

#workarea .block_map_container {
    margin-bottom: 70px;
}

#workarea .tab-content {
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 35px 30px 40px 30px;
}

#workarea #on_diagram img {
    width: 100%;
    max-width: 1200px;
}

#workarea .container_partner_title {
    width: 100%;
    background-color: #fff;
    padding: 22px;
    margin-bottom: 20px;
}

#workarea .block_border {
    width: 100%;
    height: 5px;
    background: linear-gradient(to bottom, #e0e0e2, #f2f2f2);
    margin-bottom: 20px;
}
#workarea .block_border_top {
    width: 100%;
    height: 5px;
    background: linear-gradient(to bottom, #e0e0e2, #f2f2f2);
    margin-bottom: 20px;
	display:none;
}
#workarea .block_border_bottom {
    width: 100%;
    height: 5px;
    background: linear-gradient(to bottom, #e0e0e2, #f2f2f2);
    margin-top: 20px;
	display:none;
}

/* вкладки */
#Tabs_container {
    border-bottom: 0;
	border-bottom: 0;
    clear: both;
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

#Tabs_container > li.active, .nav-tabs > li.active:hover, .nav-tabs > li.active:focus {
    color: #0066b3;
    cursor: pointer;
    background-color: #fff;
    border: 0;
    text-decoration: none;
}

#Tabs_container > li {
    margin-right: 10px;
    line-height: 1.42857143;
    border: 0;
    border-radius: 0;
    background-color: #0066b3;
    font-size: 17px;
    color: #fff;
    min-width: 175px;
    text-align: center;
    cursor: pointer;
}

#Tabs_container.nav-tabs > li {
    position: relative;
    display: block;
    padding: 20px 15px;
}

#Tabs_container > li:hover {
    border-color: #fff;
}

#Tabs_container.nav-tabs > li:hover, .nav-tabs > li:focus {
    text-decoration: none;
    background-color: #fff;
    color: #0066b3;
}

/* ����� ������� */
#Tabs_container.nav_map > li > a {
    margin: 0;
    border: 0;
    border-radius: 0;
    text-decoration: none;
    color: #fff;
    padding: 0;
    font-size: 17px;
    width: 100%;
    height: 60px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#Tabs_container.nav_map > li > a:hover {
    background-color: #fff;
    color: #0066b3;
}

#Tabs_container.nav_map > li {
    position: relative;
    display: block;
    padding: 0;
    min-width: 170px;
}

#Tabs_container > li.active > a, .nav-tabs > li.active:hover > a, .nav-tabs > li.active:focus > a {
    color: #0066b3;
    cursor: pointer;
    background-color: #fff;
    border: 0;
    text-decoration: none;
}

.partner_detail_tabs {
    margin-top: 30px !important;
}
#workarea #block_container_notab{
	display:none;
}
#workarea #block_container_notab h2{
	background-color: #0065b3;
    color: #fff;
    font-size: 23px;
    padding: 17px 20px;
	margin: 25px 0;
}
.contacts_button .contacts_link {
    width: 150px;
    height: 35px;
    margin: 25px auto;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: none;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding: 0;
    outline: none;
    cursor: pointer;
    border: 0;
}
#forma_contacts{
	display:none;
	max-width:380px;
	background-color:#fff;
	padding:30px 20px;
	text-align:center;
}
#forma_contacts .info{
	color:#2d2c2e;
	font-size:14px;
	text-align:center;
	line-height:1.4;
	padding-bottom:5px;
}
#Tabs_container.display_tabs{
	display:none;
}

@media (max-width: 1199px) {
	#workarea .container_info {
		width: calc(100% - 300px);
		background-color: #fff;
		margin-right: 10px;
		padding: 22px;
	}

	#workarea .container_forma {
		width: 280px;
		padding: 22px 20px;
	}
}
@media (max-width: 991px) {
	#Tabs_container.nav-tabs > li {
		padding: 15px 10px;
	}
	#workarea .container_forma {
		display:none;
	}
	#workarea .container_info {
		width: 100%;
		margin-right: 0;
	}
	#Tabs_container.nav_map > li > a {
		height:auto;
	}
	#workarea .container_contacts {
		margin-bottom: 0;
	}
	.contacts_button .contacts_link {
		display:block;
	}
}

@media (max-width: 768px) {
	#workarea .container_info {
		padding: 10px 20px 30px 20px;
	}
	#Tabs_container{
		width:100%;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-bottom: 20px;
		margin-top:20px;
		-webkit-justify-content: center;
		justify-content: center;
	}
	#Tabs_container.nav-tabs > li {
		padding: 12px 5px;
		border-radius: 9px;
		margin-top:10px;
		margin-left:10px;
		margin-bottom:10px;
		margin-right: 10px;
		width: 48%;
		max-width:166px;
	}
	#Tabs_container > li {
		margin-right: 0;
		font-size: 15px;
		min-width: 150px;
	}
	.partner_detail_tabs {
		margin-top: 10px !important;
	}

	#block_container_tab{
		display:none;
	}
	#Tabs_container.nav_map{
		display:none;
	}
	#workarea #block_container_notab{
		display:block;
	}
	#workarea .tab-content {
		padding:20px;
	}
	#workarea .container_h1{
		display:none;
	}
	#workarea .block_border_bottom{
		display:block;
	}
	#workarea .block_border_top{
		display:block;
	}
	#Tabs_container.display_tabs{
		display:flex;
	}
	#workarea .block_map_container {
		margin-bottom: 20px;
	}
}
@media (max-width: 670px) {
	#Tabs_container > li {
		min-width: 140px;
	}
}

@media (max-width: 500px) {
	#workarea .container_info {
		padding: 10px 15px 30px 15px;
	}
	#workarea .container_h1 .size_17px {
		font-size: 15px;
	}
	#workarea .tab-content {
		padding:15px;
	}
	#workarea .container_partner_title {
		padding: 15px;
	}
}
@media (max-width: 400px) {
	#Tabs_container.nav-tabs > li {
		width: 100%;
	}
}
