.block_equipment .container_h1{
	padding:30px 0 40px 0;
}
.block_equipment .container_h1 .size_17px{
	font-size:17px;
	padding-bottom:10px;
}
.block_equipment .block_equipment_list{
	margin:0 0 20px 0;
}
.block_equipment .block_selections_container{
	margin:20px 0 40px 0;
    float:left;
    width:100%;
}
.block_equipment .block_selections_container h2{
	font-size: 23px;
    padding-bottom: 25px;
    color: #0066b3;
    font-weight: normal;
    border-style: solid;
    border-width: 0px 0px 2px;
    -moz-border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
    -webkit-border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
    -o-border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
    border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
    margin-bottom: 30px;
	text-align:center;
}
.block_equipment .system_info {
    width: 100%;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: -40px;
}
.block_equipment .system_info .system_info_punct_padding2 {
    padding: 15px 55px 15px 105px;
}
.block_equipment .system_info .system_info_punct {
    display: flex;
    flex-direction:column;
    margin: 0 25px 0 25px;
    background-color: #0065b3;
    color: #fff;
    min-height:83px;
    border-radius: 50px;
    text-align: center;
    justify-content:center;
    align-items:center;
    line-height: 1;
}
.block_equipment .system_info_punct_background2 {
    background: url(/images/include/icon_partner2.svg) 5px 50% no-repeat;
}
.block_equipment .system_info .system_info_punct_padding3 {
    padding: 15px 25px 15px 85px;
}
.block_equipment .system_info_punct_background3 {
    background: url(/images/include/icon_partner3.svg) 5px 50% no-repeat;
}
.block_equipment .system_info .system_info_punct_number {
    font-size: 40px;
}
.block_equipment .system_info .system_info_punct_text {
    font-size: 13px;
}
.block_equipment .block_equipment_list{
	width: 100%;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    z-index: 100;
    position: relative;
    display: flex;
}
.block_equipment .block_equipment_content{
	padding: 20px 20px 40px 20px;
	background-color:#fff;
    width: 49%;
    margin: 0 0 20px 0;
    cursor: pointer;
    position: relative;
    z-index: 10;
}
.block_equipment .block_equipment_content_name{
	color:#0065b3;
	display:flex;
	min-height:75px;
	align-items: center;
	justify-content: center;
    font-size: 23px;
    padding: 0 20px;
    text-align: center;
	line-height: 1.2;
	z-index: 100;
    position: relative;
	border-bottom:1px solid #bebec0;
}
.block_equipment .block_equipment_content_img{
	text-align:center;
	padding:20px;
	min-height:255px;
	z-index: 100;
    position: relative;
}
.block_equipment .block_equipment_content_img img{
    max-height:215px;
    max-width:100%;
}
.block_equipment .block_equipment_content_link{
	width: 160px;
    height: 35px;
    margin: 0;
    background-color:#6d6e72;
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
    border: none;
	padding-top:6px;
	z-index: 100;
    position: relative;
}
.block_equipment .block_equipment_content_link:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.block_equipment .block_equipment_shadow{
	position: absolute;
    z-index: 1;
    top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    background-color: transparent;
    -webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}
.block_equipment .block_equipment_content:hover .block_equipment_shadow{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
}
.block_equipment .block_equipment_container{
	width: 100%;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    z-index: 100;
    position: relative;
    display: flex;
	padding:20px 45px 100px 45px;
}
.block_equipment .block_equipment_container .block_equipment_container_text{
	font-size:16px;
	width:48.4%;
	display:flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	justify-content: center;
}
.block_equipment .block_equipment_container .block_equipment_container_name{
	color:#0065b3;
	font-size:23px;
	padding-bottom:20px;
}
.block_equipment .block_equipment_container .block_equipment_container_slider{
	width:48.4%;
	padding:0 50px;
}
@media (max-width: 991px){
    .block_equipment .block_equipment_content{width:100%;}
    .block_equipment .block_equipment_container{padding:0 0 150px;}
    .block_equipment .block_equipment_container .block_equipment_container_text{width:100%;}
    .block_equipment .block_equipment_container .block_equipment_container_name{padding-bottom: 25px; color: #0066b3; border-style: solid; border-width: 0px 0px 2px; border-image: url(/images/index/icon_line.svg) 0 0 2 stretch; margin-bottom: 30px; text-align: center;}
    .block_equipment .block_equipment_container .block_equipment_container_slider{width:100%; max-width:400px;}
    .block_equipment .block_equipment_container .block_equipment_container_slider{margin:30px auto 0;}
    .block_equipment .system_info{flex-direction:column;}
    .block_equipment .system_info .system_info_punct{margin:10px auto 0; max-width:350px; width:100%;}
    .block_equipment .system_info{margin-top:-97px;}
    .block_equipment .block_equipment_content_img{min-height:0;}
}
@media (max-width: 500px){
    .block_equipment .container_h1 .size_17px{font-size:15px;}
    .block_equipment .container_h1{padding:20px 15px 30px;}
    .block_equipment .block_equipment_content{padding:20px 20px 30px;}
    .block_equipment .block_equipment_content_img{padding:20px 0;}
    .block_equipment .block_equipment_content_name{font-size:19px;}
    .block_equipment .block_equipment_container .block_equipment_container_name{line-height:1.2}
    .block_equipment .block_equipment_list{margin:0;}
    .block_equipment .block_equipment_container{padding-bottom:130px}
    .block_equipment .block_equipment_container .block_equipment_container_text{padding:0 15px; font-size:15px;}
    .block_equipment .block_equipment_container .block_equipment_container_slider{padding:0 15px;}
    .block_equipment .system_info .system_info_punct_number{font-size:30px;}
    .block_equipment .system_info{padding:0 10px;}
}
