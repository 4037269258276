.slider_container{
	display:flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.slider_block1{
	width:45%;
	padding:0 10px;
}
.slider_block2{
	width:55%;
	min-height:auto;
}
.slider_container .slider_block_title{
	background-color:#f3f3f5;
	color:#0065b3;
	max-width:370px;
	width:100%;
	height:65px;
	font-size:17px;
	margin:5px 0;
	display:flex;
	align-items: center;
	padding:0 20px;
	cursor:pointer;
}
.slider_container .slider_block_title_active{
	background-color:#0065b3;
	color:#fff;
	max-width:390px;
	width:auto;
	height:75px;
	font-size:17px;
	margin:5px -10px;
	display:flex;
	align-items: center;
	padding:0 20px;
	cursor:pointer;
}
.slider_container .slider_block_title:hover{
	background-color:#0065b3;
	color:#fff;
}
.slider_container .slider_block_ul{
	color:#2d2c2e;
	font-size:14px;
	padding: 15px 10px 13px 19px;
    line-height: 1.35;
	display:none;
}
.slider_container .slider_block_ul{
	padding-left: 20px;
	list-style-type: none;
}
.slider_container .slider_block_ul li {
    display: flex;
    align-items: baseline;
}
.slider_container .slider_block_ul li::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0065b3;
    margin-right: 15px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}
.slider_container #slider_block_ul1{
	display:block;
}
.slider_block2 .block_reports_slider{
	max-width:500px;
	height:310px;
	position:relative;
	z-index:10;
	border-top:13px solid #0065b3;
	border-bottom:17px solid #0065b3;
	border-left:13px solid #0065b3;
	border-right:13px solid #0065b3;
	border-radius:4px;
	margin: 10px auto;
	background-color:#fff;
	display:none;
}
.block_reports_slider .carousel-indicators .active{
	background-color:#0065b3; 
	border:0; 
	width:13px; 
	height:13px;
}
.block_reports_slider .carousel-indicators li{
	width:13px; 
	height:13px; 
	border:0; 
	background-color:#bdbec0; 
	margin:0 2px;
}
.block_reports_slider .carousel-indicators{
	bottom: -65px;
}

.block_reports_slider .carousel-fade{
	height:280px;
	width:100%;
}

.block_reports_slider .carousel-control.left{
	background-image:none;
	font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 50%;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background-color: #fff;
	left:-45px;
}

.block_reports_slider .carousel-control.right{
	background-image:none;
	font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 50%;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background-color: #fff;
	right:-45px;
}

.block_reports_slider .carousel-control{
	text-shadow:none;
	opacity: 1;
}
.block_reports_slider .carousel-control.left:hover{
	text-shadow:none;
	opacity: 1;

}

.block_reports_slider .carousel-control.left:focus{
	text-shadow:none;
	opacity: 1;
}
.block_reports_slider .carousel-control.right:hover{
	text-shadow:none;
	opacity: 1;

}

.block_reports_slider .carousel-control.right:focus{
	text-shadow:none;
	opacity: 1;
}
.block_reports_slider .slider_link{
	width:100%;
	height: 280px;
	background-color:#fff;
}
.block_reports_slider .slider_link .report_small{
	margin:0 auto;
}
.block_reports_slider .report_loupe_list{
position: absolute;
    bottom: 12px;
    right: 13px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    z-index: 1;
    border-radius: 11px;
}
.block_reports_slider .report_loupe_list:hover{
	background-color: rgba(0, 0, 0, 0.4);
	cursor:pointer;
}

#workarea #reports_list2{display:none;}

@media (max-width: 1199px) {
	.slider_block1 {
		width: 42%;
		padding: 0 10px;
	}
	.slider_block2 {
		width: 55%;
		min-height: auto;
	}
	.block_reports_slider .carousel-control.left{
		display:none;
	}
	.block_reports_slider .carousel-control.right{
		display:none;
	}
}
@media (max-width: 991px) {
	.slider_block1 {
		display:none;
	}
	.slider_block2 {
		display:none;
	}
	.slider_block2 .block_reports_slider {
		max-width: 500px;
		height: auto;
		border: none;
		margin: 10px auto;
	}
	.block_reports_slider .carousel-fade {
		height: auto;
	}
	.block_reports_slider .slider_link {
		height: auto;
	}
	#workarea #reports_list2{
		display:block;
		width:100%;
	}
	#workarea .slider_reports2{
		width:94%;
		margin: 0 auto;
		border: 1px solid #dedede;
		padding:10px 5px;
	}
	#workarea .slider_reports2 .block_slider_content1 img {
		width: 100%;
		min-height: auto;
		margin: 0 auto;
	}
	#workarea .slider_reports2 .slick-track{
		display:flex;
		justify-content: center;
		max-height:300px;
		overflow:hidden;
	}
}
@media (max-width: 500px) {
	#workarea .slider_reports2{
		width:100%;
	}
}