.page--main {
  #workarea .block_gray{
    background-color:#f3f3f4;
    padding:17px 0 30px 0;
  }
  #workarea .block_banner_gray{
    background-color:#f3f3f4;
    padding:17px 0 30px 0;
  }
  #workarea .block_row_30{
    padding:0 30px;
  }
  #workarea .block_row_60{
    padding:0 60px;
  }
  #workarea .block_row{
    padding:0;
  }
  #workarea .number_link {
    width: 150px;
    height: 35px;
    margin: 0;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin: 20px auto;
    border: none;
  }
  #workarea .number_link:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
  }
  #workarea .block_banner .block_row_h1{
    padding:35px 25px 25px 0;
  }
  #workarea .block_banner .block_row_h1 h1{
    border-style: solid;
    border-width: 0px 0px 2px;
    -moz-border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
    -webkit-border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
    -o-border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
    border-image: url(/images/index/icon_line.svg) 0 0 100% 100 stretch;
    padding-right: 70px;
    text-transform:uppercase;
    font-size:27px;
    margin-bottom:0;
  }
  #workarea .block_banner .block_row_h1 .banner_text{
    padding:19px 0 22px 0;
    color:#2d2c2e;
    font-size:14px;
  }
  #workarea .block_banner .block_row_h1 .img_bottom{
    width: 270px;
  }
  #workarea .block_banner{
    padding:0 30px;
    background:url('/images/index/block_banner_index.svg') 98.5% 30px no-repeat;
    background-color:#fff;
    max-height: 295px;
    margin-bottom:20px;
  }
  #workarea .block_banner .img_point{
    float:right;
    padding-right:20px;
    padding-top: 0;
    position:relative;
    z-index:10;
  }
  #workarea .block_banner .img_medal{
    position: absolute;
    bottom: -7px;
    left: 130px;
    z-index:10;
    width:140px;
    height:140px;
  }
  #workarea .block_solutions{
    padding:15px 30px 20px 30px;
    background:linear-gradient(to right, #1762a9, #122e51);
  }
  #workarea .block_solutions .block_text{
    color:#fff;
    text-align:center;
    font-size:23px;
    padding-bottom:15px;
  }
  #workarea .block_solutions .block_solutions_container{
    background-color:#fff;
    padding:15px 30px 15px 30px;
  }
  #workarea .bold{
    font-family: "RobotoBold", Tahoma, sans-serif;
    font-weight:600;
  }

  #workarea .block_white_border{
    background-color:#fff;
    padding:40px 0 25px 0;
    border-bottom:1px solid #eaeaeb;
  }

  #workarea .block_border{
    width:100%;
    height:7px;
    background: linear-gradient(to bottom, #eaeaeb, #fefefe);
  }

  #workarea h2{
    color:#0066b3;
    font-size:23px;
    text-align:center;
    padding:0 0 25px 0;
  }

  #workarea .block_white{
    background-color:#fff;
    padding:40px 15px 20px 15px;
  }
  #workarea .block_news{
    padding:10px 0 0 0;
  }
  #workarea .block_news h4{
    color:#2d2c2e;
    font-size:17px;
    text-transform:uppercase;
    line-height:1.3;
    padding:0 0 20px 0;
    margin:0 30px;
    border-style: solid;
    border-width: 0px 0px 1px;
    -moz-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    -webkit-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    -o-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
  }
  #workarea .block_news h5{
    color:#2d2c2e;
    font-size:17px;
    text-transform:uppercase;
    line-height:1.3;
    padding:0 0 20px 0;
    border-style: solid;
    margin-bottom:20px;
    border-width: 0px 0px 1px;
    -moz-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    -webkit-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    -o-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
    border-image: url(/images/index/icon_line.svg) 0 0 1 stretch;
  }
  #workarea .block_news .block_video_content{
    padding:0 18px 0 0;
  }
  #workarea .block_news .block_news_content{
    padding:0 0 0 18px;
  }
  #workarea .block_buttom{
    padding:50px 0 0 0;
  }
  #workarea .block_buttom .text{
    padding:0 0 40px 0;
    font-size:17px;
    color:#0066b3;
    text-align:center;
  }
  .video_scout{
    margin:20px 15px 0 0;
    box-shadow: 0px 3px 9px 3px #bcbdc0;
  }
  .video_scout.embed-responsive-16by9 {
    padding-bottom: 54.6% !important;
  }
  .youtube{width:100%; height:100%;}
  .youtube {background-position:center;background-repeat: no-repeat;background-size:100% 135%;position: absolute;display: inline-block;overflow: hidden;transition: all 200ms ease-out;cursor: pointer;}
  .youtube .play {background-size: 64px 64px;position: absolute;height: 100%;width: 100%;opacity: .8;filter: alpha(opacity=80);transition: all 0.2s ease-out;background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAERklEQVR4nOWbTWhcVRTHb1IJVoxGtNCNdal2JYJReC6GWuO83PM/59yUS3FRFARdFlwYP1CfiojQWt36sRCUurRIdVFXIn41lAoVdRGrG1M01YpKrWjiYmaSl8ybZJL3cd+YA//NLObd3++eO8x79z5jSq5Gw+8kov0AP8vMR5l1BtBZQM4B8ks75wCdZdYZZj5qLZ4hov2Nht9Z9vhKKSIaB/gI4M4w62KeAO6Mte4lYOq20FxrlqqOibhHmeWbvNC9ZfDX1mLae391aN6limO/gwgvAPJbWeAZuSDingdwXTBw7/0IsyaA/Fkh+KqOkD+YNfHej1QKD+y7iVlOhgLvFqFfNJvNGyuBJ+KDAF8MDd0tgS8y64OlgSdJMsysL4cG7SOHkyQZLhTee7+d2R2rAVy/S+Jd7/32ouBHAP4gNNRGQyTHc/84NhqNywZp5rvjjnnvt21aABFeCQ+RLwAf2hQ8s7sv9OCLk6AHNgQvIrvbfzKCD76g/O6cu7lf/iER/aQGgy448pExZmhdegAPhR9sObFWH1gT3lp7DaA/5bkIgJhZPgsNmz02novj+KqeApj1ubwXWe4kdyeznAgNvTpE/HQmvKqOMeuFogTUVQSRno+iaLRLAJF7uIgL9O4ubgL8aWgB7S44mNX+35YpICUiAvS9sBLkq1WzT+NFffl6AuoiApi6NT37h6sWkBIRZGkQ8YtLgyji6e1mBYTqCEBPG2Naz+0BWQgtoGoRgCzEsd9hAN1X5BfnFZASUfrSAFQNsyZ1FJASUVpHiLinDJG8U2cBZYogkrcNs5waBAGdstbeU9zdqpw0gPwwSAI6VUxHyFlDpOcHUUBBIuYNs14aZAE5RVwyzPr3/0EAEY0TyfGNjBWQvwZ +CTSbehfAH29mrID8bET0+0EUkAd8WYDOmqJ3ecsG30yr9wqRfm6Y+a1BEFDEjHfHvWmY9ck6CygHvBVr8Xhtb4ZE5HZA3y8DvBNA1TjnrmXWf+sioMwZX5V/VHXMGGMMoKdDCxCRvRWBdzKzdHEO+EisilbPyopHYqp6S9UCAsz4iojI7hUDAtyXVQgIDd6KnOoaWNkbI6FaPSuZGyMArsi7MZoloB4zviI/Nhr3X95jltwTRQmoIfgisy5ai+me67OI7fE4nrqjrqfK1t0eby0FPRB6oGVlchL3rgnfrq19RKbVBdhV9IOSwJmfmJi4vi/4ThERitwyCxVAFqydshuCX5awhQ9KtmuIWd8IDZED/nXT77rvVVv6sHRKwjYi91poqP7Dr+Y6JJ1VSZIMA3wkPNy6bX+o8Bcm0sXMdwM8Fxo0A3xORPaWBp6uPXsmbxCRD0NDL0dOANhVCXy6iAjMcjbcrMt3RITKwdMVRdFo+y5yvkL4eWZ+zHt/ZVD4dEVRNGotpst+dZZZH8k86lqn2pIvT/eqrNfn2xuyqYPZ8mv7s8pfn/8Pybm4TIjanscAAAAASUVORK5CYII=") no-repeat center center;}
  .youtube .play:hover {opacity: 1;filter: alpha(opacity=100);}

  /*���������� �������*/
  @media only screen and (max-width: 1199px){
    #workarea .block_banner .block_row_h1 h1 {padding-right: 10px;}
    #workarea .block_banner {max-height: 313px;}
    #workarea .block_banner .img_point {padding-right: 0px;}
    #workarea .block_banner .img_medal {left: 10px;}
    #workarea .block_news h4 br{display:none;}
  }
  @media only screen and (max-width: 991px){
    #workarea .block_banner {max-height: none;}
    #workarea .block_banner .block_row_h1 {padding: 25px;  background:url(/images/index/block_banner.svg) 100% 0 no-repeat; background-size: cover;}
    #workarea .block_banner .img_medal{position:relative; bottom: 0; left: 0; z-index: 1; margin: 20px 0 10px 15px;}
    #workarea .block_banner .img_bottom{margin-top:20px;}
    #workarea .block_banner .block_banner_img{text-align:center;}
    #workarea .block_banner {padding: 0; background: none;}
    #workarea .block_banner_gray{background-color:#fff;}
    #workarea .block_banner .block_row_h1 h1 {border-style: none; border-image: none; padding-right: 0; color:#fff;}
    #workarea .block_banner .block_row_h1 .banner_text { padding: 10px 0 20px 0; color: #fff;}
    #workarea .block_solutions .block_text, #workarea h2.block_title{color:#0066b3; border-style: solid; border-width: 0px 0px 1px; -moz-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch; -webkit-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch; -o-border-image: url(/images/index/icon_line.svg) 0 0 1 stretch; border-image: url(/images/index/icon_line.svg) 0 0 1 stretch; font-size:19px !important; text-transform:uppercase}
    #workarea .block_solutions {padding: 0; background:none;}
    #workarea .block_solutions .block_solutions_container {padding: 15px 0 0 0;}
    #workarea .video_scout_wrapper{max-width:500px; margin:0 auto 30px;}
    #workarea .block_border{display:none}
    #workarea .block_white_border{border:none;}
  }

  @media only screen and (max-width: 660px){
    #workarea .block_banner .block_row_h1 h1{font-size:22px;}
    #workarea .block_solutions .block_text {font-size: 19px; text-transform: uppercase;}
    #workarea .block_border{display:none;}
    #workarea .block_white_border{border:none;}
  }

  @media only screen and (max-width: 500px){
    #workarea .block_banner .block_row_h1 h1{font-size:19px;}
    #workarea .block_banner .block_row_h1 {padding: 15px;}
    #workarea .video_scout{border:none;}
    #workarea h2.block_title{font-size:17px !important;}
  }
}
