.typo-wrapper ul,
.list {
  li {
    color: #0066b3;
    font-size: 25px;
    padding: 17px 0;
    line-height: 0.95;
    margin: 0 30px;

    span {
        color: #2d2c2e;
        font-size: 17px;
    }
  }

  .border {
    border-bottom: 1px solid #bcbdc0;
  }
}
