.feedback-buttons {
  font-size: 16px;

  padding: 30px 0 50px 0;
  display: block;
  position: relative;
  width: 100%;
  color: #2d2c2e;

  &__text {
    font-size: 25px;
    padding-bottom: 13px;
    color: #0066b3;
    font-weight: normal;
    text-align: center;
  }

  &__row {
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__body {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    margin-top: 23px;
    padding: 25px;
    width: 48.8%;
    position: relative;
    z-index: 10;

    &--white {
      background-color: #ffffff;
    }

    &--grey {
      background-color: #f3f3f4;
    }
  }

  &__preview-text {
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
  }

  &__button {
    width: 190px;
    height: 35px;
    line-height: 35px;
    margin: 10px auto;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
    border: none;

    &:hover {
      color: #fff;
      box-shadow: 0 0 10px rgba(0,0,0,0.4);
    }
  }

  @media only screen and (max-width: 991px) {
    &__body {
      width: 100%;
    }
  }
}
