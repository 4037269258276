/*Roboto*/

@font-face {
  font-family: "RobotoThin";
  src: local(Roboto Thin), url("../../fonts/Roboto-Thin.woff2") format("woff2"), url("../../fonts/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: "RobotoLight";
  src: local(Roboto Light), url("../../fonts/Roboto-Light.woff2") format("woff2"), url("../../fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: "RobotoRegular";
  src: local(Roboto Regular), url("../../fonts/Roboto-Regular.woff2") format("woff2"), url("../../fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: "RobotoMedium";
  src: local(Roboto Medium), url("../../fonts/Roboto-Medium.woff2") format("woff2"), url("../../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: "RobotoBold";
  src: local(Roboto Bold), url("../../fonts/Roboto-Bold.woff2") format("woff2"), url("../../fonts/Roboto-Bold.woff") format("woff");
  font-weight: 700;
  font-display: fallback;
}

/*-----*/
