html {
	height:100%;
}
html, body, form {
	margin:0;
	padding:0;
	font-family: "RobotoLight", "Arial", Tahoma, sans-serif;
}
body {
	height:auto !important;
	height:100%;
	min-height:100%;
	width:100%;
	box-sizing: content-box;
	font-family:"RobotoLight", "Arial", sans-serif;
	color: #2d2c2e;
	font-size:15px;
	background-color:#fff;
	overflow-y:scroll;
	line-height:1.5;
}
.body_overflow{
	overflow-y:scroll;
}
th{
	font-weight:normal;
}
p{
	margin:0;
}
a{
	text-decoration:underline;
}
a:hover{
	text-decoration:none;
}
ul{
	padding-left: 10px;
}
ul.workarea_ul{
	padding-left: 20px;
	list-style-type: none;
}
ul.workarea_ul li {
    display: flex;
    align-items: baseline;
}
ul.workarea_ul li::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0065b3;
    margin-right: 15px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}
.clear{
	clear:both;
}

/*����� ��� footer*/
.footer_scout{
	min-height:200px;
	color:#fff;
	font-size:15px;
	padding:35px 0 40px 0;
	background: linear-gradient(to right, #1662a9, #0f2e52);
	border-top:13px solid #0066b3;
	font-size:13px;
}
.footer_scout .block_row{
	padding:0 30px;
}
.footer_scout .block_row_link{
	display:flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.footer_scout .block_row_icon{
	display:flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	padding:0 60px;
	margin-top:-10px;
	margin-bottom:10px;
}
.footer_scout .block_row_icon .social_img{
	padding: 0 5px;
}
.footer_scout .block_row_icon .social_img img{
	width:35px;
}
.footer_scout .block_col_copyright{
	padding:0 0 0 30px;
	width:21%;
	font-size:14px;
	min-height:250px;
}
.footer_scout .block_col_copyright a{
	font-size:14px;
	color:#fff;
	display:inline;
	text-decoration:none;
}
.footer_scout .block_col_copyright p{
	margin-bottom:12px;
}
.footer_scout .block_col_copyright .col_link{
	color:#fff;
	text-decoration:none;
}
.footer_scout .block_col_copyright .col_link:hover{
	color:#fff;
	text-decoration:underline;
}
.footer_scout .block_col_link{
	padding:0 0 0 30px;
	width:19%;
	font-size:13px;
	min-height:250px;
}
.footer_scout .block_col_link .link_title{
	font-family:"RobotoBold", Tahoma, sans-serif;
	margin-bottom:24px;
}
.footer_scout .block_col_link .col_link{
	color:#fff;
	text-decoration:none;
}
.footer_scout .block_col_link p{
	margin-bottom:6px;
}
.footer_scout .block_col_link .col_link:hover{
	color:#fff;
	text-decoration:underline;
}
.footer_scout .scrollup{
	position:fixed;
	width:55px;
	height:55px;
    bottom:45px;
	right:30px;
	background: url(/images/arrow_up.svg) no-repeat center center;
	background-size:contain;
	text-indent:-9999px;
	z-index:102;
	display:none;
	outline:none;
	opacity:0.8;
}
.footer_scout .scrollup:hover{
	opacity:1;
}
.footer_scout .scroll_telefon{
	position:fixed;
	width:65px;
	height:65px;
    bottom:20px;
	left:20px;
	background: linear-gradient(to right, #1662a9, #0f2e52);
	box-shadow: 0 19px 38px 0 rgba(34,36,43,0.3);
	transform: scale(-1, 1);
	border-radius:50%;
	text-indent:-9999px;
	z-index:102;
	display:none;
	outline:none;
	opacity:1;
	cursor:pointer;
}
.footer_scout .online_main{
	position:fixed;
	width:22px;
	height:22px;
    bottom:68px;
	right:83px;
	background: #c83e4b;
	border-radius:50%;
	z-index:100;
	outline:none;
	opacity:1;
	cursor:pointer;
	color:#fff;
	border:none;
	font-size:15px;
	text-align:center;
	justify-content: center;
	align-items: center;
}
.footer_scout .online_background{
	position:fixed;
    bottom:0px;
	right:0px;
	background: rgba(0,102,179,0.9);
	z-index:100;
	outline:none;
	cursor:pointer;
	color:#fff;
	border:none;
	font-size:15px;
	border-radius:300% 10px 0 0;

	-webkit-animation: swing 1s ease;
	animation: swing 1s ease;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes swing {
	from{
		width:0;
		height:0;
	}
	50%{
		width:400px;
		height:325px;
	}
	55% {
     transform: scale(1.2);
	}
	65% {
		transform: scale(1);
	}
	75% {
		transform: scale(1.1);
	}
	85% {
		transform: scale(1);
	}
	95% {
		transform: scale(1.05);
	}
	98% {
		transform: scale(1);
	}
	to{
		width:400px;
		height:325px;
	}
}

.footer_scout .online_background_close{
	width:40px;
	height:40px;
	position:fixed;
	right:15px;
	bottom:270px;
	z-index:101;
	cursor:pointer;
}
.footer_scout .online_background .online_background_text{
	position:absolute;
	right:50px;
	bottom:100px;
	color:#fff;
	font-size:28px;
}
.footer_scout .scroll_telefon a{
	width:100%;
	height:100%;
	display:block;
	background: url(../../img/images/scroll_telefon1.svg) no-repeat center center;
	background-size:contain;
}
/*����� ��� header*/
.menu-static-top{
	width:100%;
	background-color:white;
	position:fixed;
	z-index:101;
}
#header .header_top_nav{
	padding:20px 60px 17px 60px;
}
#header .header_top_nav_menu{
	padding:10px 60px 10px 60px;
}
#header .header_logo{
	float:left;
}
#header .header_logo .logo{
	width:100%;
	max-width:160px;
}
#header .header_button{
	float:right;
	padding:0 20px;
	color:#2d2c2e;
	font-size: 15px;
}
#header .header_button .group_lang .btn-default,
#header .header_button .group_lang .btn-default:hover,
#header .header_button .group_lang .btn-default:active{
	background-image:none;
	text-shadow:none;
	border:1px solid #fff;
	box-shadow:none;
	border-radius:0px;
	background-color:#fff;
	padding:7px 17px;
}
#header .header_button .group_lang .btn-default:hover,
#header .header_button .group_lang .btn-default:active,
#header .header_button .group_lang .btn-default:focus{
	border:1px solid #bcbdc0;
}
#header .header_button .group_lang .dropdown_lang,
#header .header_button .group_lang .dropdown_lang:hover,
#header .header_button .group_lang .dropdown_lang:active{
	background-image:none;
	text-shadow:none;
	border:none;
	box-shadow:none;
	border-radius:0px;
	background-color:#e7e7e8;
	padding:0;
	min-width:65px;
}
#header .header_button .group_lang .dropdown_lang li a{
	padding:6px 17px;
	text-decoration:none;
}
#header .header_button .group_lang .dropdown_lang li{
	border-bottom: 1px solid #c6c6c6;
}

#header .header_button .group_lang .dropdown_lang li:last-child{
	border-bottom: none;
}
#header .header_button .group_lang .dropdown_lang li a:hover{
	background-color:#0066b3;
	color:#fff;
	background-image:none;
	text-shadow:none;
}
#header .header_button .group_sites .btn-default,
#header .header_button .group_sites .btn-default:hover,
#header .header_button .group_sites .btn-default:active{
	background-image:none;
	text-shadow:none;
	border:1px solid #fff;
	box-shadow:none;
	border-radius:0px;
	background-color:#fff;
	padding:7px 17px;
}
#header .header_button .group_sites .btn-default:hover,
#header .header_button .group_sites .btn-default:active,
#header .header_button .group_sites .btn-default:focus{
	border:1px solid #bcbdc0;
}
#header .header_button .group_sites .dropdown_sites,
#header .header_button .group_sites .dropdown_sites:hover,
#header .header_button .group_sites .dropdown_sites:active{
	background-image:none;
	text-shadow:none;
	border:none;
	box-shadow:none;
	border-radius:0px;
	background-color:#e7e7e8;
	padding:0;
	min-width:120px;
}
#header .header_button .group_sites .dropdown_sites li a{
	padding:8px 17px;
	text-decoration:none;
}
#header .header_button .group_sites .dropdown_sites li a:hover{
	background-color:#0066b3;
	color:#fff;
	background-image:none;
	text-shadow:none;
}
#header .header_button .group_sites .dropdown_sites li{
	border-bottom: 1px solid #c6c6c6;
}
#header .header_button .group_sites .dropdown_sites li:last-child{
	border-bottom: none;
}
#header .header_search{
	float:right;
}
#header .header_telefon{
	float:right;
	padding:6px 25px;
	color: #0066b3;
	font-size: 15px;
}
#header .header_telefon_menu p{
}
#header .header_telefon_menu{
	display:none;
	float:right;
	padding:6px 25px;
	color: #0066b3;
	font-size: 15px;
}
#header .header_telefon a, #header .header_telefon_menu a{
	text-decoration:none;
	color: #0066b3;
    font-size: 15px;
	display: inline;
}
#header .header_click{
	float:right;
}
#header_menu{

}
.menu-static-top .block_border {
    width: 100%;
    height: 5px;
    background: linear-gradient(to bottom, #eaeaeb, #fefefe);
}
#header .header_click .header_contacts{
	padding:0;
	display:inline-block;
}
#header .header_click .header_contacts .number_link{
	width: 150px;
	height: 35px;
	line-height: 35px;
	margin: 0;
	background: linear-gradient(to right, #983560, #d84246);
	border-radius: 9px;
	display: block;
	text-decoration: none;
	color: #fff;
	font-size: 15px;
	text-align: center;
	outline: none;
	cursor:pointer;
	border:none;
}
#header .header_click .header_contacts .number_link:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
#workarea .number_link:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
#workarea {
	height:100%;
	min-height:500px;
	padding:0;
	width:100%;
	background-color:#fff;
	padding:138px 0 0 0;
}

.logo_header{padding:0;}
.logo_header .block_logo{display:inline-block; float:left; width:100%; max-width:300px;}
.logo_header .block_logo img{}
.block_menu{float:right; padding-top:7px; display:none; text-align:center;}
.block_menu{color:#000; padding:0 0 0 15px;}
.block_menu .btn-menu{margin-top: -8px;z-index: 1;height:22px; width:55px; background-image:url(../../img/images/left_menu.svg); background-color:#fff; background-position:50% 50%; background-repeat: no-repeat; color:#fff; font-size:14px; font-weight:normal; border:0; outline:none; text-decoration:none; border-radius:0px; padding:0;}
.block_menu .menu_text{margin: -2px 0 0 0; z-index: 2; position: relative; font-size: 13px; color: #6d6e71;}
.block_menu .btn-menu:active{border:0; outline:none; text-decoration:none; border-radius:0px; background-color:#fff; box-shadow:none;}



.footer_copyright{text-align:center; color:#fff; font-size:15px; margin:20px 0 0 0;}
.footer_block{margin:0 0 20px 0;}
.footer_block .block_link{text-align:center;}
.footer_block .block_link a{color:#fff; font-size:17px; text-align:center; display:block; text-decoration:underline; display:inline-block; padding:0 16px;}
.footer_block .block_link a:hover{text-decoration:none;}
.footer_block .block_img{min-height:40px; text-align:center;}
.header_right{padding:0;}

font.errortext{
	display:block;
	font-size:15px;
}

.modal.left .modal-dialog{
	position: fixed;
	margin: auto;
	width: 300px;
	height: 100%;
	-webkit-transform: translate3d(0%, 0, 0);
		-ms-transform: translate3d(0%, 0, 0);
		 -o-transform: translate3d(0%, 0, 0);
			transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content{
	height: 100%;
	overflow-y: auto;
}

.modal.left .modal-body{
	padding: 1px 0px 20px;
}

.modal.left.fade .modal-dialog{
	right: -300px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		 -o-transition: opacity 0.3s linear, right 0.3s ease-out;
			transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
	right: 0;
}

.modal.left .modal-content {
	border-radius: 0;
	border: none;
	background-color:#404041;
	color:#fff;
}

.modal.left .modal-header {
	border-bottom:0;
	padding:5px;
}
.modal.left .button_close{display:block; width:100%; height:45px; padding:10px 20px 0 0;}
.modal.left .close{cursor:pointer; opacity:1; font-size:14px; width:25px; height:25px; background-image:url(../../img/images/menu_button.png);
background-repeat:no-repeat; outline: none; background-size:100%;}
.modal.left .close:hover{background-image:url(../../img/images/menu_button_hover.png)}
.modal-dialog ::-webkit-scrollbar{
    width:9px;
}
.modal-dialog ::-webkit-scrollbar-thumb{
    border-width:1px 1px 1px 2px;
    border-color: #777;
    background-color: #bebec0;
}

.modal-dialog ::-webkit-scrollbar-thumb:hover{
    background-color: #ffc10d;
	cursor:pointer;
}

.modal-body hr {
	margin:10px 20px 10px 20px;
	border-top: 1px solid #6f6d75 !important;
}
.modal.left .modal-content .menu_left{
	margin:10px 0px 5px 0px;
	display:block;
	background-color:#404041;
}
.modal.left .modal-content .menu_left .link_punct:hover{
	text-decoration:none;
	background-color:#3f3d44;
	color:#fff;
}
.modal.left .modal-content .menu_left .link_punct{
	padding:6px 0 6px 42px;
	font-size:15px;
	color:#fff;
	display:block;
	text-decoration:underline;
}
.modal.left .modal-content .menu_left .title_punct{
	font-size:15px;
	color:#fff;
	display:block;
	padding:8px 0 15px 42px;
	text-transform:uppercase;
}

#workarea .block_row_30{
	padding:0 30px;
}
#workarea .block_row_60{
	padding:0 60px;
}
#workarea .block_row{
	padding:0;
}
#workarea .block_grey_container{
	background-color:#f3f3f4;
}
#workarea .scroll_forma{
	width:74px;
	height:57px;
	position:fixed;
	bottom:100px;
	right:40px;
	display:none;
	background-color: #414042;
	border-radius:4px;
	cursor:pointer;
	z-index:999;
}
#workarea .scroll_forma:hover{
	opacity:0.7;
}
#workarea .scroll_forma .scroll_link{
	color:#fff;
	display:block;
	height:37px;
	text-align:center;
	padding-top:8px;
	text-decoration:none;
}
#workarea .scroll_forma .scroll_link:hover{
	text-decoration:none;
}
#workarea .block_button_container{
	margin:50px 0 60px 0;
}
#workarea .block_button_container .block_button_container_css{
	min-width: 150px;
	display:block;
    height: 35px;
    margin: 0 auto;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
	border:0;
	padding:0 20px;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
}
#workarea .block_button_container .block_button_container_css:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
#forma_client{
	display:none;
	max-width:380px;
	background-color:#fff;
	padding:30px 20px;
	text-align:center;
}
#forma_client .info{
	color:#2d2c2e;
	font-size:14px;
	text-align:center;
	line-height:1.4;
	padding-bottom:5px;
}
.result_form{
	font-size:20px;
	padding-bottom:10px;
}

/*������� ��� fancybox*/
.fancybox-nav {
    width: 60px !important;
}

.fancybox-nav span {
    visibility: visible !important;
}

.fancybox-next {
    right: -70px !important;
}

.fancybox-prev {
    left: -70px !important;
}
/* */

.display_none{
	display:none;
}
.display_block{
	display:block;
}
.display_flex{
	display:flex;
}


/*����� ��� ���������� �������*/
@media only screen and (max-width: 1240px){

}

@media only screen and (max-width: 1200px){
	#workarea { padding: 157px 0 0 0;}
	#header .header_search{display:none !important;}
}
@media only screen and (min-width: 990px){

}
@media only screen and (max-width: 991px){
	.footer_scout .block_row {padding: 0;}
	.footer_scout .block_col_copyright{width:35%;}
	.footer_scout .block_col_link{width:30%;}
	#header_menu{display:block; position: fixed; width:100%; height:0px;  background: rgb(255, 255, 255); overflow-y:scroll; transition: height 1s;}
	#workarea { padding: 107px 0 0 0;}
	.block_menu{display:block;}
	#header .header_top_nav {padding: 20px 30px 17px 30px;}
	#header_menu.display_slow{height: calc(100% - 75px);}
	.body_overflow{overflow-y:hidden;}
	.menu-static-top .block_border {height: 2px; background: linear-gradient(to bottom, #eaeaeb, #f3f3f4);}
	#workarea .block_row_60{	padding:0 30px;}
	.fancybox-next {right: -35px !important;}
	.fancybox-prev {left: -35px !important;}
	.footer_scout .block_row_icon{padding:0 30px;}
}
@media only screen and (max-width: 768px){
	.footer_scout .block_col_copyright{width:33%;}
	.footer_scout .block_col_link{width:33%;}
	.footer_scout .scrollup{right:25px; bottom:90px; width:45px;height:45px;}
	.footer_scout .scroll_telefon{display:block;}
	.footer_scout .online_background{display:none;}
	.footer_scout .online_background_close{display:none;}
}
@media only screen and (max-width: 700px){
	#header .header_top_nav {padding: 20px 20px 17px 20px;}
	#workarea .block_row_30 { padding: 0 20px;}
	#workarea .block_row_60{	padding:0 20px;}
	.footer_scout .block_row_icon{padding:0 20px;}
}
@media only screen and (max-width: 665px){
	#header .header_button{display:none !important;}
}


@media only screen and (max-width: 590px){
	#header .header_telefon{display:none;}
	#header .header_click{float: none; clear: both; position: absolute; right: 10px;}
}

@media only screen and (max-width: 580px){
	.footer_scout .block_col_copyright{width:60%;}
	.footer_scout .block_col_link{width:40%;}
	.footer_scout .link_display1{display:none;}
	.footer_scout .block_row_icon{margin-top:10px;}
}

@media only screen and (max-width: 500px){
	.footer_scout .block_col_link{padding: 0 20px 0 10px;}
	.footer_scout .block_col_copyright {padding: 0 10px 0 20px;}
	.footer_scout { padding: 20px 0 30px 0;}
	.footer_scout .link_display2{display:none;}
	.footer_scout .block_col_copyright {padding: 0 20px; width:100%; margin: 15px 0; min-height: auto;}
	.footer_scout .scrollup{width:45px;height:45px;}
}
@media only screen and (max-width: 450px){
	.footer_scout .scrollup{right:15px;}
	#header .header_click .header_contacts {display:none;}
	#header .header_logo{width:100%; max-width:none;}
	#header .header_logo a{margin: 0 auto; display: block; width: 160px;}
}
@media only screen and (max-width: 400px){
	#header .header_top_nav {padding: 20px 10px 17px 10px;}
	#workarea .block_row_30 { padding: 0 10px;}
	#workarea .block_row_60{	padding:0 10px;}
	ul.workarea_ul{padding-left: 0;}
	.footer_scout .block_row_icon{padding:0 10px; margin-top:0;}
	.footer_scout .block_row_icon .social_img{padding: 0 3px;}
}
@media only screen and (max-width: 380px){
}
