.its-slider {
  height: 318px;

  &__button {
    top: 61%;
    font-size: 0;
    line-height: 0;
    position: absolute;
    display: block;
    width: 20px;
    height: 50%;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;

    background-repeat: no-repeat;
    background-color: transparent;

    &--prev {
      left: -30px;
      background-image: url(../../assets/img/pointer1.svg);
    }
    &--next {
      right: -30px;
      background-image: url(../../assets/img/pointer2.svg);
    }
  }

  &__text-wrap {
    display: flex;
  }

  .slick-slide {
    padding: 15px 22px !important;
    outline: none;
    width: auto;
    height: auto !important;
    position:relative;
    z-index:7;

    &__button {
      display: block;
      margin: 20px 0 20px 0;
    }
  }

  .its-slider__big-img {
    width: 100%;
  }

  .slick-slide .slider_content{
    webkit-transition: box-shadow .8s ease;
    transition: box-shadow .5s ease;
    cursor:pointer;
    position:relative;
    z-index:8;
  }

  .slick-slide .slider_content .slider_content_text{
    position:relative;
    z-index:8;
    width:100%;

    text-align: center;
  }

  .slick-slide .slider_content .slider_content_text:after{
    content:"";
    display:block;
    clear:both;
  }

  .block_margin_slider{
    margin:0 10px;
  }

  .block_slider .slider_content .slider_logo{
    height:78px;
  }

  .block_slider .slider_content .slider_logo .logo_img{
    margin:0 auto 10px auto;
  }

  .block_slider .slider_content .marker_img{
    display:inline;
    margin:-10px 10px 0 -30px;
  }

  .block_slider .slider_content .slider_img{
    width:100%;
    margin-top: 10px;
  }

  .block_slider .slider_content .slider_img .img{
    width:100%;
    margin-top:3px;
  }

  .block_slider .slider_content .slider_text1{
    width:50%;
    background:url(../../assets/img/images/block_blue_client.svg) center center no-repeat;
    background-size:100% auto;
    padding:15px 0 0 19px;
    height:78px;
    color:#fff;
    font-size:14px;
    line-height:1.35;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
  }

  .block_slider .slider_content .slider_text2{
    width:50%;
    height:78px;
    padding:10px 10px 10px 20px;
    color:#2d2c2e;
    font-size:14px;
    line-height:1.2;
    text-align:center;
  }

  .block_slider .slider_content .number1{
    font-size:23px;
  }

  .block_slider .slider_content .number2{
    font-size:30px;
  }

  .block_slider .slider_content:hover .block_slider_background {
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    margin:-10px -16px;
  }

  .block_slider .slider_content .block_slider_background{
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    -webkit-transition: all 150ms ease-out 0ms;
    -moz-transition: all 150ms ease-out 0ms;
    -o-transition: all 150ms ease-out 0ms;
    transition: all 150ms ease-out 0ms;
  }

  @media (max-width: 500px) {
    .block_margin_slider{
      margin: 0;
    }
    .block_slider .slider_content .number2{
      font-size:25px;
    }
  }

  /* Dots */
  .slick-dotted.slick-slider
  {
    margin-bottom: 30px;
  }

  .slick-dots
  {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li
  {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 3px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button
  {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus
  {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before
  {
    opacity: 1;
  }
  .slick-dots li button:before
  {
    font-size: 50px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: #bdbec0;


    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before
  {
    opacity: .75;
    color: #0065b3;
  }

  .slider_items_text_title {
    font-weight: bold;

    text-align: center;

    color: #000;
  }
  .slider_items_text {
    text-align: center;
    margin-bottom: 20px;
  }
}
