#workarea .block_button {
    padding: 30px 0 50px 0;
	display: block;
    position: relative;
    width: 100%;
    color: #2d2c2e;
    font-size: 16px;
}

#workarea .block_button .text {
	font-size: 25px;
    padding-bottom: 13px;
    color: #0066b3;
    font-weight: normal;
    text-align: center;
}

#workarea .block_button .block_button_row{
	display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
#workarea .block_button .block_button_row .flex {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
}
#workarea .block_button .block_button_col_style {
    background-color: #f3f3f4;
    margin-top: 23px;
    padding: 25px;
}
#workarea .block_button .block_button_col_50 {
    width: 48.8%;
    position: relative;
    z-index: 10;
}
#workarea .block_button .block_button_col_text {
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
}

#workarea .block_button .block_button_link.background_link {
    background: #0066b1;
}
#workarea .block_button .block_button_link {
    width: 190px;
    height: 35px;
    line-height: 35px;
    margin: 10px auto;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
    border: none;
}

#workarea .block_button .block_button_link:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
}

@media only screen and (max-width: 991px){
	#workarea .block_button .block_button_col_50{
		width: 100%;
	}
}
