.form-fp-table_zakaz input{
	width:100%;
	height:28px;
	background-color:white;
	font-size:17px;
	outline: none;
	color:#2d2c2e;
	border:none;
	border-bottom:1px solid #bdbec0;
	margin-bottom:23px;
	padding-left:5px;
}
.form-fp-table_zakaz select {
	width:100%;
	height:28px;
	background-color:white;
	font-size:17px;
	outline: none;
	color:#2d2c2e;
	border:none;
	border-bottom:1px solid #bdbec0;
	margin-bottom:15px;
}
.bx-ie select{
	padding:0px 3px 0px;
	margin-top:10px;
}

.form-fp-table_zakaz textarea{
	width:100%;
	height:150px;
	min-height:50px;
	padding-left:5px;
	margin-bottom:10px;
	margin-top:10px;
	font-size:17px;
	resize: none;
	background-color:white;
	outline:none;
	color:#2d2c2e;
	border:1px solid #bdbec0;
}

.form-fp-table_zakaz .tab4{
	display:none;
}

.form-fp-table_zakaz .button-warning{
	margin:3px auto 15px auto;
	width: 150px;
    height: 35px;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
}
.form-fp-table_zakaz .button-warning:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}

.form-fp-table_zakaz
{
	width:98%;
	border-collapse:collapse;
	font-size:17px;
	font-weight:normal;
	line-height:160%;
	color:#2d2c2e;
}

font.errortext{
	margin-bottom:30px;
}

.form-fp-table_zakaz .invisible{
	display:none;
}
.form-fp-table_zakaz .policy{
	color:#2d2c2e;
	font-size:15px;
	text-align:center;
	line-height:1.6;
}
.form-fp-table_zakaz .policy a{
	text-decoration:underline;
}
