#workarea .block_img_service_center {
    margin-bottom: 110px;
}

#workarea .center_info {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: -40px auto 0;
}

.center_info .center_info_punct {
    background-color: #0065b3;
    color: #fff;
    border-radius: 40px;
    text-align: center;
    line-height: 1;
    min-height: 83px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
}

.center_info .center_info_punct_number {
    font-size: 40px;
}

.center_info .center_info_punct_text {
    font-size: 13px;
}

.center_info_punct_background1 {
    background: url(/images/include/icon_partner1.svg) 5px 50% no-repeat;
}

.center_info_punct_background2 {
    background: url(/images/include/icon_partner2.svg) 5px 50% no-repeat;
}

.center_info_punct_background3 {
    background: url(/images/include/icon_partner3.svg) 5px 50% no-repeat;
}

.center_info .center_info_punct_padding1 {
    padding: 15px 45px 15px 100px;
}

.center_info .center_info_punct_padding2 {
    padding: 15px 55px 15px 105px;
}

.center_info .center_info_punct_padding3 {
    padding: 15px 25px 15px 85px;
}

#workarea .buttons_container {
    margin-bottom: 60px;
    text-align: center;
}

#workarea .buttons_container .support_btn:not(:last-child) {
    margin-right: 20px;
}

#forma_partner {
    display: none;
    max-width: 380px;
    background-color: #fff;
    padding: 30px 20px;
    text-align: center;
}

#forma_partner .info {
    color: #2d2c2e;
    font-size: 14px;
    text-align: center;
    line-height: 1.4;
    padding-bottom: 5px;
}

@media (max-width: 991px) {
    #workarea .center_info {
        margin-top: -93px;
    }

    .center_info .center_info_punct {
        margin: 5px 10px;
        flex-grow: 1;
    }

    .center_info .center_info_punct_number {
        font-size: 30px;
    }

    #workarea .block_system_plus h3 {
        font-size: 22px;
        padding: 0 5px 13px;
        color: #0066b3;
        font-weight: normal;
        border-style: solid;
        border-width: 0px 0px 2px;
        border-image: url(/images/index/icon_line.svg) 0 0 2 stretch;
        margin-bottom: 13px;
        background-image: none;
        text-align: center;
    }

    #workarea .block_system_plus {
        margin: 50px 0;
        padding: 0 15px;
    }
}

@media (max-width: 500px) {
    #workarea .center_info {
        margin-top: 20px;
        flex-direction: column;
    }

    #workarea .block_img_service_center {
        display: none;
    }

    #workarea .block_system_plus {
        margin: 30px 0;
    }
}

@media (max-width: 450px) {
    #workarea .buttons_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #workarea .buttons_container .support_btn {
        margin: 0 0 10px !important;
    }
}
