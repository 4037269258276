#workarea .page--razrabotka .block_system_schema {
    min-height: 970px;
    background: url("/images/support/icon_custom_solution1.svg") no-repeat center 100px, url("/images/support/bg_custom_solution.jpg") no-repeat center center;
    background-size: auto, cover;
    margin-bottom: 0;
}

#workarea .page--razrabotka .block_system_schema .schema_elem {
    position: absolute;

    max-width: 290px;
    border: 1px solid #fff;
    height: 71px;
}

.page--razrabotka .block_system_schema .elem_place1 {
    top: 172px;
    left: 136px;
    background: linear-gradient(to right, #e6864a, #f5bc48);
}

.page--razrabotka .block_system_schema .elem_place2 {
    top: 172px;
    right: 136px;
    background: linear-gradient(to right, #b8b9bd, #828387);
}

.page--razrabotka .block_system_schema .elem_place3 {
    top: 395px;
    left: 136px;
    background: linear-gradient(to right, #983561, #d64147);
}

.page--razrabotka .block_system_schema .elem_place4 {
    top: 395px;
    right: 136px;
    background: linear-gradient(to right, #1c66af, #502e7a);
}
.page--razrabotka .block_system_schema .elem_place5 {
    top: 310px;
    right: 47.8%;
	color:#fff;
	position:absolute;
}

.page--razrabotka .block_system_schema .clients_block {
    margin-top: 520px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.page--razrabotka .block_system_schema .clients_block .client {
    background-color: #fff;
    width: 49%;
    padding: 20px 20px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
}

.page--razrabotka .block_system_schema .clients_block .client_content {
    position: relative;
    z-index: 10;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.page--razrabotka .block_system_schema .clients_block .client_name {
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #bebec0;
    text-align: center;
    color: #0065b3;
    font-size: 23px;
    padding-bottom: 12px;
}

.page--razrabotka .block_system_schema .clients_block .client_description {
    margin: 30px 0 45px;
}

.page--razrabotka .block_system_schema .clients_block  .support_btn {
    margin-top: auto;
    align-self: center;
}

.page--razrabotka .block_system_schema .clients_block .its-button {
    min-width: 150px;
    margin-top: auto;
    align-self: center;
}

@media (max-width: 1199px) {
    .page--razrabotka .block_system_schema .elem_place1,
    .page--razrabotka .block_system_schema .elem_place3 {
        left: 40px;
    }

    .page--razrabotka .block_system_schema .elem_place2,
    .page--razrabotka .block_system_schema .elem_place4 {
        right: 40px;
    }
	.page--razrabotka .block_system_schema .elem_place5 {
		right: 47.3%;
	}
}

@media (max-width: 991px) {
    #workarea .page--razrabotka .block_system_schema .schema_elem {
        position: static;
        margin: 15px auto;
    }

    .page--razrabotka .block_system_schema .clients_block {
        margin-top: 0;
    }

    .page--razrabotka .block_system_schema .clients_block .client {
        width: 100%;
        margin-top: 20px;
    }
	.page--razrabotka .block_system_schema .elem_place5 {
		display:none;
	}
}
