#workarea .block_slider_client {
    padding: 0 60px 0 60px;
}

#workarea .block_slider_client .block_client_container {
    padding: 0;
}
/*Высота слайдера проставляется для IE*/
@media (min-width: 1200px) {
    .slider_review {
        height: 318px;
    }
}

@media (max-width: 1199px) {
    .slider_review {
        height: 340px;
    }
}

@media (max-width: 991px) {
    .slider_review {
        width: 381px;
        height: 338px;
        margin: 0 auto;
    }
}

@media (max-width: 660px) {
    #workarea .block_slider_client {
        padding: 0 30px;
    }
}

@media (max-width: 500px) {
    #workarea .block_slider_client {
        padding: 0;
    }

    .slider_review {
        width: 300px;
        height: 302px;
    }
}
