#workarea .block_system_schema {
    width: 100%;
    position: relative;
    background: url(/images/include/background_schema1.png) 0 0 no-repeat, url(/images/include/background_schema2.png) 100% 0 no-repeat;
    background-color: #0065b3;
    margin-bottom: 35px;
    padding: 40px;
}

#workarea .block_system_schema_white {
    background-image: none;
    background-color: #fff !important;
}

#workarea .block_system_schema h4 {
    text-align: center;
    font-size: 25px;
    color: #fff;
    padding: 0 10px;
    margin-bottom: 30px;
}

#workarea .block_system_schema_white h4 {
    color: #0066b3;
}

#workarea .block_system_schema .schema_elem {
    background-color: #0059a9;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    border-radius: 55px;
    padding: 9px;
    position: absolute;
    color: #fff;
}

#workarea .block_system_schema .schema_elem_num {
    width: 45px;
    height: 45px;
    display: flex;
    flex-shrink: 0;
    position: relative;
    border-radius: 50%;
    border: 1px solid #fff;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 17px;
}

#workarea .block_system_schema .schema_elem_text {
    display: block;
    flex-grow: 1;
    font-size: 14px;
    line-height: 17px;
}

#workarea .block_system_plus {
    background-color: #fff;
    padding: 20px;
}

.block_system_plus .block_system_plus_ul {
    margin: 15px 0 10px 0;
    list-style-type: none;
    padding: 0;
}

.block_system_plus .block_system_plus_ul li {
    padding: 12px 0 0 20px;
    display: flex;
    align-items: baseline;
    width: 93%;
}

.block_system_plus .block_system_plus_ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #0065b3;
    margin-right: 25px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}

.block_system_plus .block_system_plus_ul li:hover {
    background-color: #e7e8ea;
}

.block_system_plus_ul .block_system_plus_grey {
    font-size: 17px;
    padding-bottom: 12px;
    padding-right: 20px;
    width: 100%;
}

.block_system_plus_ul .border_bottom {
    border-bottom: 1px solid #bebec0;
}

#workarea .section_wrapper {
    background-color: #fff;
    padding: 20px;
}

#workarea .section_wrapper .block_system_plus {
    padding: 0;
}

@media (max-width: 991px) {
    #workarea .container .block_system_schema {
        background: none;
        background-color: #0065b3;
    }
}

@media (max-width: 500px) {
    #workarea .block_system_schema {
        padding: 20px 10px 10px;
        margin-bottom: 20px;
    }

    #workarea .container_h1 {
        padding: 20px 15px 30px;
    }

    #workarea .block_system_plus {
        padding: 10px;
    }

    .block_system_plus .block_system_plus_grey {
        font-size: 15px;
        padding-right: 10px;
    }

    .block_system_plus .block_system_plus_ul li {
        width: 97%;
        padding-left: 5px;
    }

    .block_system_plus .block_system_plus_ul li::before {
        margin-right: 15px;
    }

    #workarea .container_h1 .size_17px {
        font-size: 15px;
    }

    #workarea .section_wrapper {
        padding: 10px;
    }
}
