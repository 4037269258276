.its-button {
  font-size: 15px;
  height: 35px;
  line-height: 35px;
  border-radius: 10px;
  padding: 0 10px;

  display: inline-block;

  text-decoration: none;
  text-align: center;
  outline: none;
  cursor: pointer;
  border: none;


  &--base-color {
    background-color: #0065b3;
    color: #fff;
  }

  &--base-color:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    background-color: #0065b3;
    color: #fff;
  }

  &--base-color-second {
    background: linear-gradient(to right, #983560, #d84246);
    color: #fff;
  }

  &--base-color-second:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    background: linear-gradient(to right, #983560, #d84246);
    color: #fff;
  }

  &--base-color-third {
    background-color:#6d6e71;
    color: #fff;
  }

  &--base-color-third:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    background-color:#6d6e71;
    color: #fff;
  }
}
