.circliful {
    position: relative; 
}

.circle-text, .circle-info, .circle-text-half, .circle-info-half {
    width: 100%;
    position: absolute;
    text-align: center;
    display: inline-block;
}

.circle-info, .circle-info-half {
}

.circliful .fa {
	margin: 0;
	position: relative;
	bottom: 0;
}
.circlestat {
   margin: 0 auto;
}
.circle-container {
   display: block;
   float: left;
   margin: 0;
   padding:0;
   width: 105px;
   height: 105px;
}

