#workarea .block_grey{
	background-color:#f3f3f4;
}

.clear{
	float:none;
	clear:both;
}
#workarea .block_reviews_top{
	padding:40px 0 35px 0;
	float:left;
	width:100%;
}
#workarea .block_reviews_top .reviews_top_img{
	width:200px;
	float:left;
}
#workarea .block_reviews_top .reviews_top_img img{
	max-width:200px;
}
#workarea .block_reviews_top .reviews_top_text{
	width:calc(100% - 200px);
	float:left;
	padding:0 10px 0 30px;
}
#workarea .block_reviews_detail{
	padding:20px 0 0 0;
	width:100%;
}
#workarea .block_reviews_detail .detail_introduction{
	width:100%;
	background-color:#fff;
	padding:20px 20px 0 20px;
}
#workarea .block_reviews_detail .detail_introduction h2{
	background-color: #0065b3;
    color: #fff;
    font-size: 23px;
    text-transform: none;
    padding: 18px 20px;
	display:block;
	width:100%;
}
#workarea .block_reviews_detail .row_container{
	display:flex;
	flex-wrap:wrap;
	width:100%;
	font-size:15px;
}
#workarea .row_container .text_block1{
	width:50%;
	display:flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-flex-direction: column;
	flex-direction: column;
	padding:25px 25px 30px 20px;
}
#workarea .row_container .text_block2{
	width:50%;
	display:flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-flex-direction: column;
	flex-direction: column;
	padding:25px 20px 30px 25px;
}
#workarea .block_reviews_detail h3{
	text-align:center;
	font-size: 23px;
	color: #0065b3;
	padding:25px 0;
}
#workarea .block_reviews_detail .reviews_industry{
	word-wrap: break-word;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
	padding:0 0 40px 0;
	margin:0;
}
#workarea .block_reviews_detail .reviews_industry li{
	display:flex;
	list-style-type: none;
	width:210px;
	height:80px;
	background-color:#0065b3;
	border-radius:10px;
	color:#fff;
	font-size:17px;
	padding:0 20px;
	align-items: center;
	justify-content: center;
	text-align:center;
	margin:5px;
	line-height:1.25;
	cursor:pointer;
	text-decoration:none;
}
#workarea .block_reviews_detail .reviews_industry li:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
	text-decoration:none;
}
#workarea .block_reviews_detail .detail_result{
	width:100%;
	background-color:#fff;
	padding:20px 20px 0 20px;
}
#workarea .block_reviews_detail .detail_result h2{
	background: url(/images/index/pluse_title.jpg) 0 0 no-repeat;
  color: #fff;
  font-size: 23px;
  text-transform: none;
  padding: 18px 20px;
	display:block;
	width:100%;
	background-size:cover;
}
#workarea .block_reviews_detail .border_line{
	border-bottom:2px solid #e7e8ea;
}
#workarea .row_container .text_block2 .button_link{
    width: 150px;
    height: 35px;
    margin: 40px auto 10px auto;
    background-color: #6d6e72;
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding-top: 6px;
    outline: none;
    cursor: pointer;
}
#workarea .block_reviews_detail .detail_reviews{
	display:flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	width:100%;
	font-size:15px;
    background-color: #fff;
    padding: 0 20px 20px 20px;
}
#workarea .block_reviews_detail .detail_reviews .block_reviews_text{
	width:50%;
	padding:25px 25px 25px 20px;
}
#workarea .block_reviews_detail .detail_reviews .block_reviews_text_100{
	width:100%;
	padding:25px 25px 25px 20px;
}
#workarea .block_reviews_detail .detail_reviews .block_reviews_img{
	width:50%;
	margin:30px 20px 30px 25px;
	position:relative;
	cursor:pointer;
	max-height:250px;
	overflow:hidden;
}
#workarea .block_reviews_detail .detail_reviews h3{
	text-align:left;
}
#workarea .block_reviews_detail .detail_reviews .block_reviews_img .img_reviews{
	width:100%;
	position:relative;
	z-index:1;
	filter: brightness(60%);
	-webkit-transition: all 200ms ease-out 0ms;
    -moz-transition: all 200ms ease-out 0ms;
    -o-transition: all 200ms ease-out 0ms;
    transition: all 200ms ease-out 0ms;
}
#workarea .block_reviews_detail .detail_reviews .block_reviews_img:hover .img_reviews{
	width:110%;
	margin:-5%;
}

#workarea .block_reviews_detail .detail_reviews .block_reviews_img .icon_reviews{
	width:50px;
	position:absolute;
	z-index:10;
	bottom:0;
	left:45%;
}

#workarea .block_reviews_detail .detail_introduction ul,
#workarea .block_reviews_detail .detail_result ul{
	padding-left: 10px;
	list-style-type: none;
}
#workarea .block_reviews_detail .detail_introduction ul li,
#workarea .block_reviews_detail .detail_result ul li {
    display: flex;
    align-items: baseline;
}
#workarea .block_reviews_detail .detail_introduction ul li::before ,
#workarea .block_reviews_detail .detail_result ul li::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #0065b3;
    margin-right: 15px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
}


@media (max-width: 991px){
	#workarea .block_reviews_top{float:none; display:flex; flex-direction:column;}
	#workarea .block_reviews_top .reviews_top_text{float:none; width:100%; padding:0;}
	#workarea .block_reviews_top .reviews_top_img{float:none; align-self:center; margin-bottom:20px;}
	#workarea .row_container .text_block1{width:100%; padding:20px 20px 0;}
	#workarea .row_container .text_block2{width:100%; padding:0 20px 20px;}
	#workarea .block_reviews_detail .detail_reviews{flex-direction:column}
	#workarea .block_reviews_detail .detail_reviews .block_reviews_text{width:100%; padding:20px;}
	#workarea .block_reviews_detail .detail_reviews .block_reviews_img{order:-1; width:100%; max-width:300px; margin: 30px auto 0}
	#workarea .reviews_text_container iframe{width:100%}
	#workarea .block_reviews_detail .detail_reviews h3{text-align:center;}
}
@media (max-width: 500px){
	#workarea .block_reviews_top{padding:20px 15px 30px}
	#workarea .row_container .text_block1{padding:20px 0 0;}
	#workarea .block_reviews_detail .detail_introduction{padding: 15px 15px 0}
	#workarea .row_container .text_block2{padding: 0 0 15px}
	#workarea .block_reviews_detail .detail_reviews .block_reviews_text{padding: 20px 0 0}
	#workarea .block_reviews_detail .detail_result{padding: 15px 15px 0}
	#workarea .block_reviews_detail .detail_reviews{padding: 0 15px 12px;}
	#workarea .block_reviews_detail .detail_reviews .block_reviews_text_100{padding:0;}
}
@media (max-width: 400px){
	#workarea .block_reviews_detail .detail_introduction ul,
	#workarea .block_reviews_detail .detail_result ul{
		padding-left: 0;
	}
}
