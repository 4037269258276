 #workarea .block_info h2 {
    color: #0065b3;
    font-size: 25px;
    padding: 18px 20px;
	max-width:616px;
}
#workarea .block_info{
	width:100%;
}
#workarea .block_map_container #map{
	width:100%; 
	height:500px; 
	padding:0; 
	margin:0;
}
#workarea .block_map_container #map a{
	color:#1a3dc1;
}
#workarea .block_map_container #map h2{
    padding: 12px 0;
	line-height: 0.95;
}
#workarea .block_map_container #map .partner_name{
	font-size:16px;
}
#workarea .block_map_container #map .map_shop_adress{
	line-height: 100%;
    font-size: 13px;
}
#workarea .block_map_container #map .map_shop_phones{
	margin-top: 5px;
	font-size:14px;
	color:#000;
}
#workarea .block_map_container #map .map_shop_phones a{
	cursor:pointer;
}
#workarea .block_filter{
	margin:25px 0 20px 0;
	display:flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	width:100%;
	flex-wrap:wrap;
	-webkit-align-items: center;
	align-items: center;
}
#workarea .block_filter_item{
	display:flex;
	align-items:center;
}
#workarea .block_filter .block_filter_name{
	padding:0 10px 0 20px;
	font-size:17px;
}
#workarea .block_filter .block_filter_input{
	padding:0 20px 0 0px;
	font-size:16px;
}
#workarea .block_filter .block_filter_input select{
	width:230px;
	height:30px;
	outline:none;
	padding:0 8px;
	border:1px solid #bebebe;
	cursor:pointer;
	-moz-appearance: none;
    -webkit-appearance: none;
	margin-top:0;
	background: url("/images/select_arrow.svg") no-repeat right 8px center;
	background-size:15px 15px;
}
#workarea .block_filter .block_filter_input select::-ms-expand { 
    display: none;
}

#workarea .block_filter .block_filter_input select option{
	cursor:pointer;
	outline:none;
	background-color:#e7e8ea;
}
#workarea .block_filter .block_filter_button .input_button{
	width: 150px;
    height: 35px;
    margin: 20px 20px 0 20px;
    background:#0065b3;
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
    border: none;
}
#workarea .block_filter .block_filter_button .input_button:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
@media (max-width:1199px){
	.block_filter_button{margin: 20px auto 0}
}
@media (max-width:991px){
	#workarea .block_info h2{max-width:none;}
	#workarea .block_filter{flex-direction:column}
	#workarea .block_filter .block_filter_name{width:150px; text-align:right; padding-left:0;}
	#workarea .block_filter_item{margin:5px 0;}
	#workarea .block_filter .block_filter_input{padding:0;}
}
@media (max-width:500px){
	#workarea .block_filter_item{display:block;}
	#workarea .block_filter .block_filter_name{width:auto; text-align:left;}
}