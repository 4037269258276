.bx-blue.bx-top-nav .bx-top-nav-container {
	background: #fff;
}
/*.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl.bx-active>a::after{
	border-bottom:3px solid #b93c54;
	opacity: 1;
    bottom: -0px;
    border-bottom: 0px solid #b93c54;
    -webkit-box-shadow: inset 0px -3px 0px 0px #b93c54;
    -moz-box-shadow: inset 0px -3px 0px 0px #b93c54;
    box-shadow: inset 0px -3px 0px 0px #b93c54;
}
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl>a:hover::after {
	opacity: 1;
    bottom: -0px;
}
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl>a::after {
	content: '';
    position: absolute;
    left: 10%;
    bottom: 20%;
    opacity: 0;
    width: 80%;
    height: 100%;
	border-bottom: 0px solid #b93c54;
    -webkit-box-shadow: inset 0px -3px 0px 0px #b93c54;
    -moz-box-shadow: inset 0px -3px 0px 0px #b93c54;
    box-shadow: inset 0px -3px 0px 0px #b93c54;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}*/
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl.bx-hover>a,
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl:hover>a {
	border-bottom:3px solid #0066b3;
}
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl.bx-active>a{
	border-bottom:3px solid #b93c54;
}
/*2 lvl*/
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-2-lvl>a.bx-active,
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-2-lvl>a:hover {
	color: #fff;
	background: #0066b3;
	text-decoration:none;
}
/*3 lvl*/
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-3-lvl a.bx-active,
.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-3-lvl a:hover {
	color: #fff;
	background: #0066b3;
	text-decoration:none;
}

.bx-top-nav-container {
	background: #fff;
	color: #2d2c2e;
	border-radius: 2px;
	position: relative;
	padding:0 34px;
}

.bx-top-nav-container .bx-nav-list-1-lvl {
	margin: 0 0 0 -4px;
	padding: 0;
	list-style: none;
	display:flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.bx-top-nav-container .bx-nav-1-lvl {
	position: relative;
}
.bx-top-nav-container .bx-nav-1-lvl>a {
	color: #2d2c2e;
	display: block;
	text-decoration: none;
	font-size:17px;
	margin:0 8px;
}
.bx-top-nav-container .bx-nav-1-lvl>a span {
	padding: 17px 8px 12px 8px;
	display: block;
}
.bx-top-nav-container .bx-nav-1-lvl>a .menu_icon{
	width:33px;
	height:33px;
	margin: 11px 8px 10px 8px;
}
.bx-top-nav-container .bx-nav-1-lvl>a .menu_icon_margin{
	margin: 4px 8px;
}
.bx-top-nav-container .bx-nav-1-lvl.bx-hover {}

/*.bx-top-nav-container .bx-nav-1-lvl.bx-nav-parent.bx-hover>a,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-parent.hover>a {
	z-index: 250;
	box-shadow: 0 6px 13px 0 rgba(0, 0, 0, .13)
}*/
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-parent.bx-hover>a span,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-parent.hover>a span {

}
.bx-top-nav-container .bx-nav-parent-arrow{
	display: none;
}
/*2 lvl*/

.bx-top-nav-container>ul{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bx-top-nav-container .bx-nav-list-2-lvl {
	margin: 0;
	padding: 0;
}
.bx-top-nav-container .bx-nav-2-lvl {
	display: block;
	min-width: 100%;
	position:relative;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bx-top-nav-container .bx-nav-2-lvl>a {
	padding: 10px 10px;
	color: #414042;
	font-size: 14px;
	line-height: 16px;
	text-decoration: none;
	border-bottom: none;
	display: block;
	border-bottom:1px solid #c6c6c6;
}
.bx-top-nav-container .bx-nav-2-lvl:last-child a{
    border-bottom:none;
}

.bx-top-nav-container .bx-nav-list-2-lvl .bx-nav-2-lvl:first-child a{
	border-top: none;
	margin-top: 0;
}

/*3 lvl*/

.bx-top-nav-container .bx-nav-3-lvl {
	padding: 5px 0;
	margin: 0;
}
.bx-top-nav-container .bx-nav-3-lvl-container-advanced:before,
.bx-top-nav-container .bx-nav-3-lvl-container:before {
	position: absolute;
	content: " ";
	display: block;
	height: 5px;
	background: #fff;
	top: -5px;
	left: 0;
	right: 0;
}
.bx-top-nav-container .bx-nav-list-3-lvl {
	margin: 0;
	padding: 10px 0px;
	position: absolute;
	display: none;
	opacity:0;
	left:232px;
	top:-10px;
	background-color: #e7e7e8;
}
.bx-top-nav-container .bx-nav-2-lvl:hover .bx-nav-list-3-lvl {
	display:block;
	opacity:1;
}
.bx-top-nav-container .bx-nav-3-lvl {
	display: block;
	min-width: 230px;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bx-top-nav-container .bx-nav-3-lvl a {
	padding: 7px 10px;
	color: #414042;
	font-size: 14px;
	line-height: 16px;
	text-decoration: underline;
	border-bottom: none;
	display: block;
}





/* col 1 */
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-1-col .bx-nav-2-lvl-container-advanced,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-1-col .bx-nav-2-lvl-container {
	min-width: 230px;
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-1-col .bx-nav-2-lvl-container-advanced .bx-nav-list-2-lvl,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-1-col .bx-nav-2-lvl-container .bx-nav-list-2-lvl {
	display: block;
	min-width: 230px;
	width: 100%;
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-1-col .bx-nav-catinfo-back{
	width: 100%;
	background: #fff;
}
/* col 2 */
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-2-col .bx-nav-2-lvl-container-advanced,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-2-col .bx-nav-2-lvl-container {
	min-width: 230px
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-2-col .bx-nav-2-lvl-container-advanced .bx-nav-list-2-lvl,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-2-col .bx-nav-2-lvl-container .bx-nav-list-2-lvl {
	display: block;
	min-width: 230px;
	width: 50%;
	float: left;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-2-col .bx-nav-catinfo-back{
	width: 50%;
}
/* col 3 */
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-3-col .bx-nav-2-lvl-container-advanced,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-3-col .bx-nav-2-lvl-container {
	min-width: 600px
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-3-col .bx-nav-2-lvl-container-advanced .bx-nav-list-2-lvl,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-3-col .bx-nav-2-lvl-container .bx-nav-list-2-lvl {
	display: block;
	min-width: 230px;
	width: 33.33333%;
	float: left;
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-3-col .bx-nav-catinfo-back{
	width: 33.33333%;
}
/* col 4 */
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-4-col {position: static;}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-4-col .bx-nav-2-lvl-container-advanced{
	top: 100%;
	left: 0;
	right: 0;
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-4-col .bx-nav-2-lvl-container-advanced,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-4-col .bx-nav-2-lvl-container{
	min-width: 100%
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-4-col .bx-nav-2-lvl-container-advanced .bx-nav-list-2-lvl,
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-4-col .bx-nav-2-lvl-container .bx-nav-list-2-lvl {
	display: block;
	width: 25%;
	float: left;
}
.bx-top-nav-container .bx-nav-1-lvl.bx-nav-list-4-col .bx-nav-catinfo-back{
	width: 25%;
}

.bx-top-nav-container .bx-nav-catinfo{
	padding: 5px 15px 5px;
	z-index: 120;
	position: relative;
}
.bx-top-nav-container .bx-nav-catinfo img{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid #e7e9ea;
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
}
.bx-top-nav-container .bx-nav-catinfo p{
	padding: 20px 5px 0;
	font-size: 13px;
}
.bx-top-nav-container .bx-nav-catinfo-back{
	position: absolute;
	display: block;
	content: ' ';
	top: -5px;
	right: 0px;
	bottom: 0px;
	background: #f7fafb;
	z-index: 100;
	border-left: 1px solid #ecefef;
}
/* ASIDE */
/*body>.bx-aside-nav-control.bx-opened{left: 85%;}*/

.bx-aside-nav-control {
	font-size: 28px;
	position: fixed;
	width: 40px;
	height: 40px;
	top: 5px;
	left: 5px;
	line-height: 40px;
	cursor: pointer;
	z-index: 1750;
	color: #fff;
	text-align: center;
}
body>.bx-aside-nav-control{
	-webkit-transition: left .15s linear;
	-moz-transition: left .15s linear;
	-ms-transition: left .15s linear;
	-o-transition: left .15s linear;
	transition: left .15s linear;
}
.caret_menu{
	display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
	border-left: 4px dashed;
    border-left: 4px solid \9;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
	float:right;
	margin-top:4px;
}

.bx-wrapper,
.bx-aside-nav {
	-webkit-transition: -webkit-transform .15s linear;
	-moz-transition: -moz-transform .15s linear;
	-ms-transition: -ms-transform .15s linear;
	-o-transition: -o-transform .15s linear;
	transition: transform .15s linear;
}
.bx-logo {
	z-index: 1700 !important
}
/**/
.bx-opened .bx-footer,
.bx-opened .workarea{display:none !important}
/**/
.bx-aside-nav {
	display: none;
	padding-top: 50px;
	-webkit-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	-o-transform: translateX(-100%);
	transform: translateX(-100%);
	width: 100%;
	background: #4c5c65;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 1600;
}
.bx-aside-nav.bx-opened {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
	overflow-y: scroll;

}
.bx-aside-nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.bx-aside-nav a {
	color: #fff;
	vertical-align: middle;
	line-height: 48px;
	height: 48px;
	font-size: 16px;
	display: block;
	border-bottom: 1px solid #3d4b53;
	padding-left: 15px;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	-ms-text-overflow: ellipsis;
	text-overflow: ellipsis;
}
.bx-aside-nav a {
	text-decoration: none;
}
.bx-aside-nav .bx-nav-1-lvl {}
.bx-aside-nav .bx-nav-1-lvl>a {}
.bx-nav-1-lvl.bx-opened,
.bx-aside-nav .bx-nav-1-lvl:hover>a,
.bx-aside-nav .bx-nav-1-lvl.bx-hover>a {
	background: #44535c;
}
.bx-aside-nav .bx-nav-list-2-lvl {
	background: #44535c;
}
.bx-aside-nav .bx-nav-2-lvl>a {
	padding-left: 30px;
}
.bx-aside-nav .bx-nav-3-lvl>a {
	padding-left: 45px;
}
.bx-aside-nav .bx-nav-4-lvl a {
	padding-left: 60px;
}
.bx-aside-nav .bx-nav-parent {
	position: relative;
}
.bx-aside-nav .bx-nav-parent-arrow {
	position: absolute;
	right: 0;
	top: 0;
	margin-top: 6px;
	width: 46px;
	height: 34px;
	text-align: center;
	border-left:1px solid #5a6971;
	z-index: 200;
}
.bx-aside-nav .bx-nav-parent-arrow i {
	line-height: 34px;
	font-size: 17px;
	font-style: normal;
	color: #fff;
}
.bx-aside-nav .bx-nav-parent.bx-opened .bx-nav-parent-arrow i {
	-webkit-transition: all .15s linear;
	-moz-transition: all .15s linear;
	-ms-transition: all .15s linear;
	-o-transition: all .15s linear;
	transition: all .15s linear;
}
.bx-aside-nav .bx-nav-parent .bx-nav-2-lvl-container-advanced,
.bx-aside-nav .bx-nav-parent .bx-nav-2-lvl-container,
.bx-aside-nav .bx-nav-parent>ul {
	overflow: hidden;
	height: 0;
}
.bx-aside-nav .bx-nav-parent.bx-opened .bx-nav-2-lvl-container-advanced,
.bx-aside-nav .bx-nav-parent.bx-opened .bx-nav-2-lvl-container,
.bx-aside-nav .bx-nav-parent.bx-opened>ul {
	height: auto;
}

.bx-aside-nav .bx-nav-catinfo{display: none;}
.bx-aside-nav .bx-nav-catinfo-back{display: none;}

.bx-top-nav .number_link {
    width: 150px;
	height: 35px;
	line-height: 35px;
    margin: 0;
    background: linear-gradient(to right, #983560, #d84246);
    border-radius: 9px;
    display: none;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin: 20px auto;
    border: none;
}

.bx-top-nav .number_link:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}

.menu-only-mobile__item {
	display: none;
}

@media (min-width: 992px) {
	.bx-top-nav-container .bx-nav-1-lvl.bx-hover .bx-nav-2-lvl-container-advanced,
	.bx-top-nav-container .bx-nav-1-lvl.hover .bx-nav-2-lvl-container-advanced,
	.bx-top-nav-container .bx-nav-1-lvl.bx-hover .bx-nav-2-lvl-container,
	.bx-top-nav-container .bx-nav-1-lvl.hover .bx-nav-2-lvl-container {
		opacity: 1;
		display: block;
		position: absolute;
		top: 100%;
		z-index: 999;
		left:18px;
		background-color:#e7e7e8;
	}
	.bx-top-nav .fa{display:none;}
	.bx-top-nav-container .bx-nav-2-lvl-container-advanced,
	.bx-top-nav-container .bx-nav-2-lvl-container {
		z-index: 999;
		display: none;
		opacity: 0;
		padding: 15px 0 15px 0;
		min-width: 100%;
		color: #2d2c2e;
		background-color:#e7e7e8;
	}
	.bx-top-nav-container .bx-nav-2-lvl-container-advanced:before,
	.bx-top-nav-container .bx-nav-2-lvl-container:before {
		position: absolute;
		content: " ";
		display: block;
		height: 0px;
		background: #fff;
		top: 0px;
		left: 0;
		right: 0;
	}
	#menu-sites-6{display:none;}
	.bx-top-nav .lvl_color{color:#414042 !important;}
}

@media (max-width: 1199px) {
	.bx-top-nav-container .bx-nav-1-lvl>a {font-size: 15px; margin:0 5px;}
	.bx-top-nav-container .bx-nav-1-lvl>a span {padding: 12px 3px 15px}
	.bx-top-nav-container .bx-nav-list-1-lvl {margin: 0;}
	.bx-top-nav-container .bx-nav-1-lvl>a .menu_icon{margin: 8px;}
	.bx-top-nav-container .bx-nav-1-lvl>a .menu_icon_margin{margin: 2px 8px 3px 8px;}
}
@media (max-width: 991px) {
	.bx-top-nav-container {padding: 0 20px 20px 20px;}
	.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl.bx-active>a {border-bottom:none;}
	.bx-top-nav-container .bx-nav-1-lvl {width: 100%; border-bottom: 1px solid #e7e8ea;}
	.bx-top-nav-container .bx-nav-1-lvl>a { margin: 0 5px; display: inline-block; width: 100%;}
	.bx-top-nav-container .bx-nav-1-lvl>.width_link { width: calc(100% - 67px);}
	.bx-top-nav-container .bx-nav-1-lvl>a span {padding: 15px 0px 15px;}
	.bx-top-nav-container .bx-nav-2-lvl-container {z-index: 10; display: none; opacity: 1; padding: 0; min-width: 100%; width:100%; color: #2d2c2e; background-color:#e7e7e8;}
	.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl.bx-hover>a, .bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-1-lvl:hover>a {border-bottom: none;}
	.bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-2-lvl>a.bx-active, .bx-blue.bx-top-nav .bx-top-nav-container .bx-nav-2-lvl>a:hover { background-color: #e7e8ea; color:#414042; }
	.bx-top-nav-container .bx-nav-2-lvl>a {text-decoration:none; padding: 15px 10px; font-size: 14px;}
	.bx-top-nav-container .bx-nav-2-lvl{border-bottom:1px solid #fff;}
	.bx-top-nav-container .bx-nav-2-lvl:last-child{border-bottom:none;}
	.bx-top-nav-container .bx-nav-list-2-lvl{padding:0 10px;}
	.bx-top-nav .fa{ float: right; font-size: 22px; color:#3d3d3d; margin: 0; padding: 15px 20px 15px 20px; border-left: 1px solid #e7e8ea; position:relative; z-index:10; cursor:pointer; min-width:56px;}
	.bx-top-nav .display_lvl{display:block !important;}
	#menu-sites-6{display:block;}
	#menu-sites-6>p { padding: 15px 0px 15px; }
	#link-container6{display:inline-block; margin:0 5px;}
	.bx-top-nav .lvl_color{color:#0066b3 !important;}
	.bx-top-nav-container .bx-nav-list-1-lvl {margin: 0;}
	.menu-only-mobile__item {
		display: block;
	}
	.arrow-its {
		position: absolute;
		top: 25px;
		width: 3vmin;
		height: 3vmin;
		background: transparent;

		border-top: 1vmin solid rgb(45, 44, 46);
		border-right: 1vmin solid rgb(45, 44, 46);
		box-shadow: 0 0 0 lightgray;
		transition: all 200ms ease;

		&.arrow-its--right {
			right: 0;
			transform: translate3d(0,-50%,0) rotate(45deg);
		}

		&.arrow-its--down {
			right: 0;
			transform: translate3d(0,-50%,0) rotate(135deg);
		}

		&:hover {
			border-color: orange;
			box-shadow: 0.5vmin -0.5vmin 0 white;
		}

		&:before { // for better hit area
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-40%,-60%) rotate(45deg);
			width: 200%;
			height: 200%;
		}
	}
}


@media (max-width: 450px) {
	.bx-top-nav .number_link {display:block;}
}

@media (max-width: 400px) {
	.bx-top-nav-container {padding: 0 10px 20px 10px;}
}
