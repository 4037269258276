/* item_detail */
.bx_item_detail{
	font-size:16px;
}
.bx_item_detail .margin_bottom_row{
	margin-bottom:40px;
}
.bx_item_detail .container_h1{
	padding:30px 0 8px 0;
}
.bx_item_detail .equipment_detail_block5{
	background-color:#fff;
	padding:30px 40px 40px 30px;
	margin:20px 0 10px 0;
	display: block;
    position: relative;
    width: 100%;
	color:#2d2c2e;
	font-size:18px;
}
.bx_item_detail .equipment_detail_row_link{
	display:flex;
	flex-wrap:wrap;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
}
.bx_item_detail .equipment_detail_col_link{
	width:45%;
	margin-bottom:20px;
}
.bx_item_detail .equipment_detail_block5 a{
	display:block;
	padding-top:20px;
	color:#0065b3;
	font-size:18px;
	text-decoration:underline;
}
.bx_item_detail .equipment_detail_block5 a:hover{
	text-decoration:none;
}
.bx_item_detail .background_white{
	background-color:#fff;
}
.bx_item_detail .equipment_detail_block4{
	padding:30px 20px 15px 20px;
	display: block;
    position: relative;
    width: 100%;
	color:#2d2c2e;
}
.bx_item_detail .border_top {
    border-top: 1px solid #e8e8e8;
}
.bx_item_detail .block_save{
	padding:0 20px;
}
.bx_item_detail .block_save_all {
    padding: 30px 0;
}
.bx_item_detail .link_save_all {
    width: 125px;
    height: 35px;
    margin: 0;
    background: #0065b3;
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding-top: 6px;
    outline: none;
    cursor: pointer;
}
.bx_item_detail .link_save_all:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.bx_item_detail .block_save_container{
	display:flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.bx_item_detail .block_save_container_item{
	width:45%;
	padding:25px 0 35px 0;
}
.bx_item_detail .block_save_container_item_name{
    width: 100%;
    padding: 0 0 20px 0;
    color: #2d2c2e;
    font-size: 17px;
}
.bx_item_detail .block_save_container_item_link{
	width: 125px;
    height: 35px;
    margin: 0;
    background: #6d6e72;
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding-top: 6px;
    outline: none;
    cursor: pointer;
}
.bx_item_detail .block_save_container_item_link:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.bx_item_detail .equipment_detail_block1{
	display:flex;
	margin-bottom:22px;
	padding:50px 25px 25px 25px;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
}
.bx_item_detail .equipment_detail_block1_slider{
	width:47.5%;
}
.bx_item_detail .equipment_detail_block1_info{
	width:47.5%;
}
.bx_item_detail .equipment_detail_block1_text{
	font-size:17px;
	color:#2d2c2e;
	padding-bottom:15px;
	line-height: 1.3;
}
.bx_item_detail .block_equipment_content_property{
   display: flex;
	width: 100%;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    z-index: 10;
	padding:15px 0 20px 0;
}
.bx_item_detail .block_equipment_content_property_block{
	max-width:95px;
	font-size:13px;
	text-align:center;
	margin:0 10px;
}
.bx_item_detail .block_equipment_content_property_block_icon{
	background-color:#f3f3f3;
	border-radius:50%;
	width:55px;
	height:55px;
	display:flex;
	align-items: center;
	justify-content: center;
	margin:0 auto 10px auto;
}
.bx_item_detail .equipment_detail_block1_button{
	padding-top:15px;
}
.bx_item_detail .equipment_detail_block1_button .button{
	width: 160px;
    height: 35px;
    margin: 0;
    background-color: #6d6e72;
    border-radius: 9px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin: 22px auto 0 auto;
    border: none;
    z-index: 100;
    position: relative;
	line-height: 35px;
}
.bx_item_detail .equipment_detail_block1_button .button:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.bx_item_detail .equipment_detail_block2{
	padding: 25px 20px 30px 20px;
    display: block;
    position: relative;
    width: 100%;
    color: #2d2c2e;
}
.bx_item_detail .block_border {
    width: 100%;
    height: 5px;
    background: linear-gradient(to bottom, #eaeaeb, #fefefe);
	margin-bottom:35px;
	z-index: 10;
    position: relative;
}
.bx_item_detail .button_all{
	width: 115px;
    height: 38px;
    background-color: #0065b3;
    border-radius: 20px;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin: 0px auto -21px auto;
    border: none;
    z-index: 100;
    position: relative;
}
.bx_item_detail .button_all:hover{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.bx_item_detail .equipment_detail_block2_container{
	width:100%;
	z-index: 10;
    position: relative;
}
.bx_item_detail .block_equipment_plus_ul{
    margin: 0;
	display:block;
    width: 100%;
    list-style-type: disc;
    list-style-position: outside;
    font-size: 25px;
    color: #0065b3;
    position: relative;
    padding: 0 15px 0 40px;
}
.bx_item_detail .block_equipment_plus_ul li{
    padding: 5px 0 0 5px;
    position: relative;
    width: 100%;
}
.bx_item_detail .block_equipment_plus_grey {
	font-size: 16px;
    color: #2d2c2e;
    display: block;
    width:100%;
    padding-bottom: 12px;
    position: relative;
    z-index: 10;
	line-height: 1.5;
}
.bx_item_detail .border_bottom{
	border-bottom: 1px solid #bebec0;
}
.bx_item_detail .border_top{
	border-top: 1px solid #bebec0;
}
.bx_item_detail .equipment_detail_block2_container_hidden{
	display:none;
}

.bx_item_detail .equipment_detail_block3{
	padding: 25px 20px 30px 20px;
    display: block;
    position: relative;
    width: 100%;
    color: #2d2c2e;
}
.bx_item_detail .equipment_detail_block3_container{
	width:100%;
	z-index: 10;
    position: relative;
	padding:35px 20px 0px 20px;
	font-size: 15px;
}
.bx_item_detail .equipment_detail_block3_container_visible{
	display:flex;
	z-index: 10;
    position: relative;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
}
.bx_item_detail #equipment_block3_hidden{
	display:none;
}
.bx_item_detail .equipment_detail_block3_container_hidden{
	display:flex;
	z-index: 10;
    position: relative;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: stretch;
	align-items: stretch;
}
.bx_item_detail .block_content_title{
	background-color:#e4ddcb;
	width:100%;
	padding:10px 25px 10px 15px;
}
.bx_item_detail .block_content_text{
	width:50%;
	padding:10px 25px 10px 15px;
}
.bx_item_detail .color_gray{
	background-color:#f3f3f3;
}
.bx_item_detail .color_brown{
	background-color:#e4ddcb;
}
.bx_item_detail .color_white{
	background-color:#fff;
}

.bx_item_detail .equipment_detail_block6{
	padding: 25px 20px 0px 20px;
    display: block;
    position: relative;
    width: 100%;
    color: #2d2c2e;
}
.bx_item_detail .equipment_detail_block6_container{
	width:100%;
	z-index: 10;
    position: relative;
	padding:20px;
}
.bx_item_detail ul, .bx_item_detail ol{
	padding-left:30px;
}
.bx_item_detail .equipment_detail_block6_container p{
	padding:0;
	margin:0;
	word-wrap:break-word;
}
.bx_item_detail .equipment_detail_block6_container img{
	width:100%;
	padding:10px;
}
.bx_item_detail #equipment_slider_container{
	padding:0 20px;
}
#forma_client_equipment{
	display:none;
	max-width:380px;
	background-color:#fff;
	padding:30px 20px;
	text-align:center;
}
#forma_client_equipment .info{
	color:#2d2c2e;
	font-size:14px;
	text-align:center;
	line-height:1.4;
	padding-bottom:5px;
}

@media (max-width: 991px){
	.bx_item_detail .equipment_detail_block1_info{width:100%;}
	.bx_item_detail .equipment_detail_block1_slider{width:100%; max-width:500px; margin: 0 auto 30px;}
	.bx_item_detail .equipment_detail_block1_button{padding:0;}
	.bx_item_detail .block_save_container_item{width:100%;}
	.bx_item_detail .equipment_detail_col_link{width:100%;}
	.bx_item_detail .equipment_detail_block5{padding-bottom:10px;}
}
@media (max-width: 660px){
	.bx_item_detail .color_gray{width:100%;}
	.bx_item_detail .color_white{width:100%;}
}
@media (max-width: 500px){
	.bx_item_detail .container_h1{padding: 20px 15px;}
	.bx_item_detail .equipment_detail_block1_text{font-size:15px;padding-bottom:0;}
	.bx_item_detail .equipment_detail_block1, .bx_item_detail .equipment_detail_block2, .bx_item_detail .equipment_detail_block3,
	.bx_item_detail .equipment_detail_block4, .bx_item_detail .equipment_detail_block5, .bx_item_detail .equipment_detail_block6{padding:20px 15px;}
	.bx_item_detail .block_equipment_plus_ul{padding-left:30px;}
	.equipment_detail_block2{font-size:15px;}
	.bx_item_detail .block_border{margin-bottom:15px;}
	.bx_item_detail .equipment_detail_block3_container{padding:15px 0 0;}
	.bx_item_detail .block_save{padding: 0}
	.bx_item_detail .block_save_container_item{padding:20px 0}
	.bx_item_detail .equipment_detail_row_link{font-size:15px;}
	.bx_item_detail .block_save_container_item_name, .bx_item_detail .equipment_detail_block5 a{font-size:15px;}
	.bx_item_detail .block_equipment_plus_grey{font-size:15px;}
	.bx_item_detail .equipment_detail_block6_container{padding:15px 0; font-size:15px;}
	.bx_item_detail .equipment_detail_block5{font-size:15px;}
}
