#workarea .block_industry .item_industry:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

#workarea .block_industry .text_link {
    color: #0066b3;
    text-decoration: none;
    font-size: 14px;
    display: block;
    width: 100%;
    text-align: center;
}
#workarea .block_industry_container{
    outline:none;
}
#workarea .block_industry .slick-arrow {
    top: 50%;
}

#workarea .block_industry .slick-dots {
    left: 0;
}

#workarea .block_industry .slick-dots li button::before {
    font-size: 16px;
    color: #bdbec0;
    opacity: 1 !important;
}

#workarea .block_industry .slick-dots li {
    margin: 0;
}

#workarea .block_industry .slick-dots li.slick-active button:before {
    color: #0065b3 !important;
}

#workarea .block_industry .text_link:hover {
    text-decoration: none;
	color: #0066b3;
}

#workarea .block_industry .block_industry_container {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#workarea .block_industry {
    padding: 0px 30px 10px 30px;
}

#workarea .block_industry .block_text {
    color: #2d2c2e;
    text-align: center;
    font-size: 17px;
    padding-bottom: 25px;
}

#workarea .bold {
    font-family: "RobotoBold", Tahoma, sans-serif;
    font-weight: 600;
}

#workarea .block_industry .item_industry {
    background-color: #fff;
	cursor:pointer;
    /*background:url(/images/index/block_white1.svg) 0 0 no-repeat;*/
    padding:12px;
    width: 18%;
    display: flex !important;
    flex-shrink: 0;
    align-items: center;
	justify-content: center;
    border-radius: 10px;
    line-height: 1.3;
    height: 85px;
    margin: 5px;
    webkit-transition: box-shadow .8s ease;
    transition: box-shadow .5s ease;
}

@media (max-width: 1199px) {
    #workarea .block_industry .item_industry {
        margin: 5px 0;
    }

    #workarea .block_industry .block_industry_container {
        max-width: 400px;
        margin: 0 auto;
    }
}
