#workarea .block_news {
    display: flex;
    flex-wrap: wrap;
}

#workarea .block_news .more_btn {
    display: block;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 0;
    background: #0065b3;
    border-radius: 9px;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    outline: none;
    cursor: pointer;
    margin: 20px auto 10px auto;
    border: none;
}

#workarea .block_news .more_btn:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.block_video_content,
.block_news_content {
    width: 50%;
    flex-shrink: 0;
}

@media (max-width: 991px) {
    #workarea .block_news {
        padding-top: 10px;
    }

    .block_video_content,
    .block_news_content {
        width: 100%;
    }

    #workarea .block_news .block_video_content {
        padding: 0;
    }

    #workarea .block_news .block_news_content {
        padding: 0;
    }

    #workarea .block_news h4,
    #workarea .block_news h5 {
        margin: 0;
        text-align: center;
        color: #0066b3;
        font-size: 19px;
    }

    #workarea .block_news h5 br {
        display: none;
    }
}

@media (max-width: 500px) {
    #workarea .news-list .news-item1 .news-date-time,
    #workarea .news-list .news-item1 .news-name {
        font-size: 15px;
    }

    #workarea .news-list .news-item1 {
        padding: 15px 0;
    }

    #workarea .block_news h4,
    #workarea .block_news h5 {
        font-size: 17px;
    }
}
