#workarea .block_info{
	width:100%;
	float:left;
	margin-right:20px;
}

#workarea .block_info .info_line{
	margin-left:20px;
	padding:13px 0;
	float:left;
	width:calc(100% - 20px);
	font-size:16px;
	line-height:1.3;
}
#workarea .block_info .info_line.border_bottom{
	border-bottom:2px solid #f3f3f5;
}
#workarea .block_info .info_line span{
	color:#2d2c2e;
	font-size:14px;
}
#workarea .block_info .info_line .info_line_td1{
	float:left;
	width:calc(100% - 200px);
	color:#0065b3;
}
#workarea .block_info .info_line .info_line_td2{
	float:left;
	width:200px;
	padding:0 10px;
}
#workarea .block_info .info_line .info_line_td2 a{
	font-size:16px;
	color:#2d2c2e;
	text-decoration:none;
}
#workarea .block_info .info_line .info_line_td2 .email{
	color:#0065b3;
	font-size:16px;
	text-decoration:underline;
}
#workarea .block_info .info_line .info_line_td2 .email:hover{
	text-decoration:none;
}
#workarea .block_icon{
	width:90px;
	float:left;
	margin-left:20px;
	font-size:13px;
	color:#2d2c2e;
	text-align:center;
	line-height:1.3;
}
#workarea .block_icon img{
	padding:10px 0 5px 0;
}
#workarea .block_icon p{
	padding:5px 0 15px 0;
}
#workarea .clear{
	clear:both;
}
#workarea .block_address{
	width:100%;
	padding-left:20px;
	margin-top:10px;
}
#workarea .block_address p{
	font-size:16px;
	color:#2d2c2e;
	padding:0 0 20px 0;
	line-height:1.5;
}

#workarea .block_address .button_schema {
    margin: 10px 0;
    width: 150px;
    height: 38px;
    background-color:#0065b3;
    border-radius: 9px;
	border:none;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
}

@media (max-width: 768px) {
	#workarea .block_info .info_line .info_line_td1{
		width:calc(100% - 160px);
	}
	#workarea .block_info .info_line .info_line_td2{
		width:160px;
	}
	#workarea .block_info h2 {
		margin-bottom:10px;
	}
}
@media (max-width: 500px) {
	#workarea .block_info .info_line .info_line_td1{
		float:none;
		width:100%;
		padding:7px 0;
	}
	#workarea .block_info .info_line .info_line_td2{
		width:100%;
		float:none;
		padding:7px 0;
		margin:0;
	}
	#workarea .block_info .info_line {
		margin-left: 0;
		padding:0 16px;
		float: none;
		width: 100%;
		font-size: 16px;
		line-height:1.5;
	}
	#workarea .block_info h2 {
		font-size:20px;
	}
	#workarea .block_address {
		padding-left: 16px;
	}
	#workarea .block_address p {
		font-size: 15px;
		padding: 0;
	}
}
