.block_solutions_wrapper{
	padding: 0 35px;
}
.block_solutions{
	display:flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.block_solutions .item_solutions{
	width:19%;
	height:80px;
	max-width:208px;
	display:flex !important;
	background-color:#0065b3;
	color:#fff;
	margin:10px 5px;
	border-radius:10px;
	align-items: center;
	justify-content: center;
	text-align:center;
	font-size:17px;
	text-decoration:none;
	cursor:pointer;
	padding:0 20px;
	line-height:1.25;
}
.block_solutions .item_solutions:hover{
	text-decoration:none;
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.block_solutions.slick-slider{
	max-width:400px;
	margin: 0 auto;
}
.block_solutions.slick-slider .slick-slide>div{
	margin:0 3px;
}
.block_solutions.slick-slider .item_solutions{
	max-width:none;
	margin:5px 0;
}
@media (max-width: 1199px) {
	.block_solutions .item_solutions{width:23%;}
}
@media (max-width: 991px) {
	.block_solutions .item_solutions{width:33%;}
}
@media (max-width: 660px) {
	.block_solutions .item_solutions{flex-basis:205px;}
}
@media (max-width: 500px) {
	.block_solutions .item_solutions{font-size:15px;}
}