.hero-text {
  &--base-img-file {
    background:url(/local/components/scout/catalog/templates/solutions_20180228/bitrix/catalog.section.list/.default/images/icon1_list_solutions.jpg) 20px 25% no-repeat;
  }

  &--base {
    padding: 40px 0;
  }

  &--only-title {
    padding: 30px 0 8px 0;
  }

  &--with-img {
    padding: 30px 0 50px 200px;
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: 20px 25%;
  }

  &__text {
    font-size:17px;
  }

  &--multiline &__text {
    margin-bottom: 15px;
  }

  @media (max-width: 991px) {
		padding: 40px 0 40px 0;
		background-image: none;
  }

  @media (max-width: 500px) {
    padding: 20px 15px 30px;

    &__text {
      font-size: 15px;
    }
  }
}
